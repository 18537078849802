export const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      fill="none"
      viewBox="0 0 15 14"
    >
      <path
        stroke="#004AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.74 2h1.695a.56.56 0 01.565.55v9.9a.56.56 0 01-.565.55H1.565A.56.56 0 011 12.45v-9.9A.56.56 0 011.565 2H11.74zM4 1v2M10 1v2M1 5h13"
      />
    </svg>
  );
};
