import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { TOKEN_STORAGE_KEY } from '@libs/api';
import { Edital } from '@pages/Edital/[id]';

const editaisSearchSchema = z.object({
  token: z.string().optional(),
});

export const Route = createFileRoute('/edital/$id')({
  component: Edital,
  validateSearch: (search) => editaisSearchSchema.parse(search),
  beforeLoad: async ({ search }) => {
    if (search?.token) {
      localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(search?.token));
    }
  },
});
