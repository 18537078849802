export const StampIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        stroke="#004AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.34 14.4h12.73v1.34c0 .368-.302.67-.67.67H3.01a.672.672 0 01-.67-.67V14.4zM15.07 11.05h-2.03c-3.33 0-1.99-3.35-1.99-7.705C11.05 1.67 9.998 1 8.705 1 7.412 1 6.36 1.67 6.36 3.345c0 4.355 1.34 7.705-2.01 7.705H2.34c-.737 0-1.34.603-1.34 1.34v2.01h15.41v-2.01c0-.737-.603-1.34-1.34-1.34z"
      />
    </svg>
  );
};
