export const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      fill="none"
      viewBox="0 0 20 19"
    >
      <path stroke="#fff" strokeMiterlimit="10" d="M10 2.023v15.545" />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 2.432C6.932 1.155 5.91 1 1 1v15.546c4.786.04 6.136.204 9 1.431 2.864-1.227 4.09-1.431 9-1.431V1c-4.91 0-5.523.155-9 1.432zM3.455 6.523h4.09M3.455 8.977h4.09M3.455 11.432h4.09"
      />
    </svg>
  );
};
