import { useState, useEffect } from 'react';
import { Edital } from '@libs/types';
import { EditalDetailsModalProps } from './types.ts';
import { DetailsContainer } from './styles';
import ModalCustom from '../ModalCustom';
import { DocIcon } from '@assets/icons';
import { formatDateToLocale } from '@utils/formatDateToLocale';

export const EditalDetailsModal = ({
  edital,
  isOpen,
  onClose,
}: EditalDetailsModalProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the width threshold according to your needs
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Initial check for mobile
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ModalCustom isOpen={isOpen} setModalState={onClose} width="90%">
      <DetailsContainer>
        {isMobile ? (
          // Render mobile version here
          <div>
            {
              <div className="container">
                <div className="main-tab">
                  <div className="div-one">
                    <div className="header">
                      <div className="column">
                        <div id="card-title" className="one">
                          <div id="icon">
                            <DocIcon />
                          </div>
                          Dados Do Edital
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="div-two">
                    <div className="row">
                      <div id="bold" className="column three">
                        Nome do Órgão Promotor:
                      </div>
                      <div id="blue" className="column two">
                        {edital.orgaoPromotor.nome}
                      </div>
                      <div id="bold" className="column">
                        CNPJ:
                      </div>
                    </div>
                    <div className="row">
                      <div id="blue" className="column four">
                        {edital.orgaoPromotor.documento}
                      </div>
                    </div>
                  </div>
                  <div className="div-three">
                    <div className="row">
                      <div id="bold" className="column">
                        Modalidade
                      </div>
                      <div className="column">{edital.modalidade.name}</div>
                      <div id="bold" className="column">
                        Utiliza Verba Federal
                      </div>
                      <div className="column">
                        {edital?.verbaFederal ? 'Sim' : 'Não'}
                      </div>
                      <div id="bold" className="column">
                        Amparo Legal
                      </div>
                      <div className="column">
                        {edital?.leisVerbaFederal?.[0]?.descricao || '-'}
                      </div>
                    </div>
                    <div className="row"></div>
                  </div>
                  <div className="div-four">
                    <div className="row">
                      <div id="bold" className="column">
                        Finalidade da Licitação / Operação
                      </div>
                      <div className="column">
                        {edital.finalidadeLicitacao.name}
                      </div>
                      <div id="bold" className="column">
                        Número do Edital
                      </div>
                      <div className="column">{edital.numeroEdital}</div>
                      <div id="bold" className="column">
                        Número do Processo
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">{edital.numeroProcesso}</div>
                    </div>
                  </div>
                  <div className="div-five">
                    <div className="row">
                      <div id="bold" className="column">
                        Pregoeiro / Agente de Contratação
                      </div>
                      <div className="column">{edital.pregoeiro.nome}</div>
                      <div id="bold" className="column">
                        Telefone
                      </div>
                      <div className="column">{edital.pregoeiro.telefone}</div>
                      <div id="bold" className="column">
                        Email
                      </div>
                      <div id="bold" className="column" />
                    </div>
                    <div className="row">
                      <div className="column">{edital.pregoeiro.email}</div>
                    </div>
                  </div>
                  <div className="div-six">
                    <div className="row">
                      <div id="bold" className="column">
                        Unidade Compradora
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        {edital.unidadeCompradora.nome}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="div-eight">
                    <div className="header">
                      <div id="blue" className="column">
                        Equipe de Apoio
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        {edital?.equipeDeApoio?.map((equipe: EquipeDeApoio) => (
                          <div className="row" key={equipe.membroEquipeId}>
                            <span className="value">{equipe.nome}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="div-nine">
                    <div className="row">
                      <div id="blue" className="column">
                        Objeto e segmentação da Licitação
                      </div>
                      <div id="bold" className="column">
                        Objetos
                      </div>
                      <div className="column">
                        {edital.segmentacoes.map((segmentacao) => (
                          <div key={segmentacao.uniqueId}>
                            {segmentacao.objeto}
                          </div>
                        ))}
                      </div>
                      <div id="bold" className="column">
                        Segmentos
                      </div>
                      <div className="column">
                        {edital.segmentacoes.map((segmentacao) => (
                          <div key={segmentacao.uniqueId}>
                            {segmentacao.segmento}
                          </div>
                        ))}
                      </div>
                      <div id="blue" className="column">
                        Julgamento e Validade da Proposta
                      </div>
                    </div>
                  </div>
                  <div className="div-ten">
                    <div className="row">
                      <div id="bold" className="column">
                        Critérios de julgamento da proposta e lance
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        {edital.criterioJulgamento.name}
                      </div>
                    </div>
                  </div>
                  <div className="div-eleven"></div>
                  <hr />
                  <div className="div-twelve">
                    <div className="header">
                      <div id="blue" className="column">
                        Data e Hora Referente a
                      </div>
                    </div>
                  </div>
                  <div className="div-thirteen">
                    <div className="row">
                      <div id="bold" className="column">
                        Início do recebimento de propostas
                      </div>
                      <div className="column">
                        {formatDateToLocale(
                          edital.inicioRecebimentoPropostas,
                        ) || '-'}
                      </div>
                      <div id="bold" className="column">
                        Término do recebimento
                      </div>
                      <div className="column">
                        {formatDateToLocale(
                          edital.terminoRecebimentoPropostas,
                        ) || '-'}
                      </div>
                      <div id="bold" className="column">
                        Abertura e Análise
                      </div>
                      <div className="column">
                        {formatDateToLocale(edital.inicioAnalisePropostas) ||
                          '-'}
                      </div>
                      <div id="bold" className="column">
                        Início de Lances
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        {formatDateToLocale(edital.inicioLances) || '-'}
                      </div>
                    </div>
                  </div>{' '}
                  <hr />
                  <div className="div-fourteen">
                    <div className="header">
                      <div id="blue" className="column">
                        Sala de disputa
                      </div>
                    </div>
                    <div className="row"></div>
                  </div>
                  <div className="div-fifteen">
                    <div className="row">
                      <div id="bold" className="column">
                        Modo de disputa
                      </div>
                      <div className="column">
                        {edital?.modoDisputa?.name || '-'}
                      </div>
                      <div id="bold" className="column">
                        Os lances devem considerar o valor
                      </div>
                      <div className="column">
                        {edital?.tipoValorLance?.name || '-'}
                      </div>
                    </div>
                    <div className="row"></div>
                  </div>
                </div>
              </div>
            }
          </div>
        ) : (
          // Render desktop version here
          <div>
            {
              <div className="container">
                <div className="main-tab">
                  <div className="div-one">
                    <div className="header">
                      <div className="column">
                        <div id="card-title" className="one">
                          <div id="icon">
                            <DocIcon />
                          </div>
                          Dados Do Edital
                        </div>
                      </div>
                      <div className="column"></div>
                      <div className="column"></div>
                      <div className="column"></div>
                    </div>
                  </div>
                  <hr />
                  <div className="div-two">
                    <div className="row">
                      <div id="bold" className="column three">
                        Nome do Órgão Promotor:
                      </div>
                      <div id="bold" className="column">
                        CNPJ:
                      </div>
                    </div>
                    <div className="row">
                      <div id="blue" className="column two">
                        {edital.orgaoPromotor.nome}
                      </div>
                      <div id="blue" className="column four">
                        {edital.orgaoPromotor.documento}
                      </div>
                    </div>
                  </div>
                  <div className="div-three">
                    <div className="row">
                      <div id="bold" className="column">
                        Modalidade
                      </div>
                      <div id="bold" className="column">
                        Utiliza Verba Federal
                      </div>
                      <div id="bold" className="column">
                        Amparo Legal
                      </div>
                      <div className="column"></div>
                    </div>
                    <div className="row">
                      <div className="column">{edital.modalidade.name}</div>
                      <div className="column">
                        {edital?.verbaFederal ? 'Sim' : 'Não'}
                      </div>
                      <div className="column">
                        {edital?.leisVerbaFederal?.[0]?.descricao || '-'}
                      </div>
                      <div className="column"></div>
                    </div>
                  </div>
                  <div className="div-four">
                    <div className="row">
                      <div id="bold" className="column">
                        Finalidade da Licitação / Operação
                      </div>
                      <div id="bold" className="column"></div>
                      <div id="bold" className="column">
                        Número do Edital
                      </div>
                      <div id="bold" className="column">
                        Número do Processo
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        {edital.finalidadeLicitacao.name}
                      </div>
                      <div className="column"></div>
                      <div className="column">{edital.numeroEdital}</div>
                      <div className="column">{edital.numeroProcesso}</div>
                    </div>
                  </div>
                  <div className="div-five">
                    <div className="row">
                      <div id="bold" className="column">
                        Pregoeiro / Agente de Contratação
                      </div>
                      <div id="bold" className="column">
                        Telefone
                      </div>
                      <div id="bold" className="column">
                        Email
                      </div>
                      <div id="bold" className="column" />
                    </div>
                    <div className="row">
                      <div className="column">{edital.pregoeiro.nome}</div>
                      <div className="column">{edital.pregoeiro.telefone}</div>
                      <div className="column">{edital.pregoeiro.email}</div>
                      <div className="column" />
                    </div>
                  </div>
                  <div className="div-six">
                    <div className="row">
                      <div id="bold" className="column">
                        Unidade Compradora
                      </div>
                      <div id="bold" className="column"></div>
                      <div id="bold" className="column"></div>
                      <div id="bold" className="column"></div>
                    </div>
                    <div className="row">
                      <div className="column">
                        {edital.unidadeCompradora.nome}
                      </div>
                      <div className="column"></div>
                      <div className="column"></div>
                      <div className="column"></div>
                    </div>
                  </div>
                  <div className="div-seven">
                    <div className="row">
                      <div id="bold" className="column"></div>
                      <div id="bold" className="column"></div>
                      <div id="bold" className="column"></div>
                      <div id="bold" className="column"></div>
                    </div>
                    <div className="row">
                      <div className="column"></div>
                      <div className="column"></div>
                      <div className="column"></div>
                      <div className="column"></div>
                    </div>
                  </div>
                  <hr />
                  <div className="div-eight">
                    <div className="header">
                      <div id="blue" className="column">
                        Equipe de Apoio
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        {edital?.equipeDeApoio?.map((equipe: EquipeDeApoio) => (
                          <div className="row" key={equipe.membroEquipeId}>
                            <span className="value">{equipe.nome}</span>
                          </div>
                        ))}
                      </div>
                      <div className="column"></div>
                    </div>
                  </div>
                  <hr />
                  <div className="div-nine">
                    <div className="row">
                      <div id="blue" className="column">
                        Objeto e segmentação da Licitação
                      </div>
                      <div className="column"></div>
                      <div id="blue" className="column">
                        Julgamento e Validade da Proposta
                      </div>
                    </div>
                  </div>
                  <div className="div-ten">
                    <div className="row">
                      <div id="bold" className="column">
                        Objetos
                      </div>
                      <div id="bold" className="column">
                        Segmentos
                      </div>
                      <div id="bold" className="column">
                        Critérios de julgamento da proposta e lance
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        {edital.segmentacoes.map((segmentacao) => (
                          <div key={segmentacao.uniqueId}>
                            {segmentacao.objeto}
                          </div>
                        ))}
                      </div>
                      <div className="column">
                        {edital.segmentacoes.map((segmentacao) => (
                          <div key={segmentacao.uniqueId}>
                            {segmentacao.segmento}
                          </div>
                        ))}
                      </div>
                      <div className="column">
                        {edital.criterioJulgamento.name}
                      </div>
                    </div>
                  </div>
                  <div className="div-eleven">
                    <div className="row">
                      <div id="bold" className="column"></div>
                      <div id="bold" className="column"></div>
                      <div id="bold" className="column"></div>
                    </div>
                    <div className="row">
                      <div className="column"></div>
                      <div className="column"></div>
                      <div className="column"></div>
                    </div>
                  </div>
                  <hr />
                  <div className="div-twelve">
                    <div className="header">
                      <div id="blue" className="column">
                        Data e Hora Referente a
                      </div>
                    </div>
                  </div>
                  <div className="div-thirteen">
                    <div className="row">
                      <div id="bold" className="column">
                        Início do recebimento de propostas
                      </div>
                      <div id="bold" className="column">
                        Término do recebimento
                      </div>
                      <div id="bold" className="column">
                        Abertura e Análise
                      </div>
                      <div id="bold" className="column">
                        Início de Lances
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        {formatDateToLocale(
                          edital.inicioRecebimentoPropostas,
                        ) || '-'}
                      </div>
                      <div className="column">
                        {formatDateToLocale(
                          edital.terminoRecebimentoPropostas,
                        ) || '-'}
                      </div>
                      <div className="column">
                        {formatDateToLocale(edital.inicioAnalisePropostas) ||
                          '-'}
                      </div>
                      <div className="column">
                        {formatDateToLocale(edital.inicioLances) || '-'}
                      </div>
                    </div>
                  </div>{' '}
                  <hr />
                  <div className="div-fourteen">
                    <div className="header">
                      <div id="blue" className="column">
                        Sala de disputa
                      </div>
                    </div>
                    <div className="row">
                      <div className="column"></div>
                    </div>
                  </div>
                  <div className="div-fifteen">
                    <div className="row">
                      <div id="bold" className="column">
                        Modo de disputa
                      </div>
                      <div id="bold" className="column">
                        Os lances devem considerar o valor
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        {edital?.modoDisputa?.name || '-'}
                      </div>
                      <div className="column">
                        {edital?.tipoValorLance?.name || '-'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        )}
      </DetailsContainer>
    </ModalCustom>
  );
};
