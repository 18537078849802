export const ThreeDotsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="5"
      fill="none"
      viewBox="0 0 17 5"
    >
      <path
        stroke="#004AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.5 1a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM2.5 1a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM8.5 1a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
      />
    </svg>
  );
};
