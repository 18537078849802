export const editaisEmptyMock = {
  editais: [],
  count: 0,
};

export const editaisMock = {
  editais: [
    {
      uniqueId: 'd2d6a3d5-c440-4c2b-bd58-59ffb70b635b',
      numeroEdital: '011/2024R',
      numeroProcesso: '020/2024R',
      editalStatus: {
        id: 5,
        name: 'Publicado',
      },
      orgaoPromotor: {
        orgaoPromotorId: '56c804da-63c3-4a39-a8a0-46d778c73cbe',
        uniqueId: '56c804da-63c3-4a39-a8a0-46d778c73cbe',
        documento: '06553986000103',
        nome: 'Isaias Coelho',
        uf: 'PI',
        razaoSocial: 'Prefeitura Municipal de Isaias Coelho',
        nomeFantasia: 'Isaias Coelho',
        unidadesCompradoras: [],
      },
      unidadeCompradora: {
        unidadeCompradoraId: '56c804da-63c3-4a39-a8a0-46d778c73cbe',
        documento: '06553986000103',
        nome: 'Isaias Coelho',
        razaoSocial: 'Prefeitura Municipal de Isaias Coelho',
      },
      modalidade: {
        id: 5,
        name: 'Dispensa',
      },
      segmentacoes: [
        {
          objetoId: 'e0480944-d883-40f4-bd31-984be69bbd41',
          objeto: 'Engenharia - serviços',
          segmentoId: '46c87185-4c97-4282-8b62-cf29ddbf65fa',
          segmento: 'Projetos de engenharia e arquitetura, maquetes',
        },
      ],
      inicioRecebimentoPropostas: '2024-04-04T09:00:00-03:00',
      createdAt: '2024-04-03T20:48:45.222862',
      publishAt: '2024-04-03T20:54:49.504',
      edicaoPosPublicacao: false,
      statusSalaDisputa: {
        id: 2,
        name: 'Não iniciado',
      },
    },
    {
      uniqueId: '41465246-f25f-4185-aff9-dfbad077a65f',
      numeroEdital: '005/2024R',
      numeroProcesso: '014/2024R',
      editalStatus: {
        id: 5,
        name: 'Publicado',
      },
      orgaoPromotor: {
        orgaoPromotorId: '56c804da-63c3-4a39-a8a0-46d778c73cbe',
        uniqueId: '56c804da-63c3-4a39-a8a0-46d778c73cbe',
        documento: '06553986000103',
        nome: 'Isaias Coelho',
        uf: 'PI',
        razaoSocial: 'Prefeitura Municipal de Isaias Coelho',
        nomeFantasia: 'Isaias Coelho',
        unidadesCompradoras: [],
      },
      unidadeCompradora: {
        unidadeCompradoraId: '56c804da-63c3-4a39-a8a0-46d778c73cbe',
        documento: '06553986000103',
        nome: 'Isaias Coelho',
        razaoSocial: 'Prefeitura Municipal de Isaias Coelho',
      },
      modalidade: {
        id: 5,
        name: 'Dispensa',
      },
      segmentacoes: [
        {
          objetoId: 'e251bad4-5b7c-4cee-a2f1-8a1f8e6f4a61',
          objeto: 'Produtos de limpeza',
          segmentoId: '077b9b3d-3258-4a30-b221-d9248f714db5',
          segmento: 'Produtos de higiene, limpeza e higiene pessoal',
        },
      ],
      inicioRecebimentoPropostas: '2024-04-04T08:00:00-03:00',
      createdAt: '2024-04-03T20:41:44.379678',
      publishAt: '2024-04-03T20:58:41.798',
      edicaoPosPublicacao: false,
      statusSalaDisputa: {
        id: 2,
        name: 'Não iniciado',
      },
    },
    {
      uniqueId: '6a9640db-77ca-41db-84dc-3ed4de9fd4fc',
      numeroEdital: '003/2024',
      numeroProcesso: '019/2024',
      editalStatus: {
        id: 5,
        name: 'Publicado',
      },
      orgaoPromotor: {
        orgaoPromotorId: '7ba49a09-8006-4874-8833-ef6823e32b1c',
        uniqueId: '7ba49a09-8006-4874-8833-ef6823e32b1c',
        documento: '45196698000109',
        nome: 'Bananal',
        uf: 'SP',
        razaoSocial: 'Prefeitura Municipal da Estância Turística de Bananal',
        nomeFantasia: 'Bananal',
        unidadesCompradoras: [],
      },
      unidadeCompradora: {
        unidadeCompradoraId: '7ba49a09-8006-4874-8833-ef6823e32b1c',
        documento: '45196698000109',
        nome: 'Bananal',
        razaoSocial: 'Prefeitura Municipal da Estância Turística de Bananal',
      },
      modalidade: {
        id: 3,
        name: 'Pregão (Setor público)',
      },
      segmentacoes: [
        {
          objetoId: '5967ce5c-cf79-4e94-8f9e-289ddf560eee',
          objeto: 'Combustíveis',
          segmentoId: 'd033f98c-a5d2-4ad0-a9aa-09fab0008bae',
          segmento: 'Combustíveis e Lubrificantes',
        },
      ],
      inicioRecebimentoPropostas: '2024-04-03T21:00:00-03:00',
      inicioAnalisePropostas: '2024-04-15T07:30:00-03:00',
      createdAt: '2024-04-03T20:38:39.188387',
      publishAt: '2024-04-03T20:50:56.431',
      edicaoPosPublicacao: false,
      statusSalaDisputa: {
        id: 3,
        name: 'Em execução',
      },
    },
    {
      uniqueId: 'de6b6ac0-b309-4e2e-b2ac-d073018a7b76',
      numeroEdital: '032/2024',
      numeroProcesso: '032/2024',
      editalStatus: {
        id: 5,
        name: 'Publicado',
      },
      orgaoPromotor: {
        orgaoPromotorId: '53d4a373-cd99-4aab-ac0d-8dc1f1e48b84',
        uniqueId: '53d4a373-cd99-4aab-ac0d-8dc1f1e48b84',
        documento: '08996378000107',
        nome: 'Consórcio 8 de Abril',
        uf: 'SP',
        razaoSocial: 'Consórcio Intermunicipal de Saúde 8 de Abril',
        nomeFantasia: 'Consórcio 8 de Abril',
        unidadesCompradoras: [],
      },
      unidadeCompradora: {
        unidadeCompradoraId: '53d4a373-cd99-4aab-ac0d-8dc1f1e48b84',
        documento: '08996378000107',
        nome: 'Consórcio 8 de Abril',
        razaoSocial: 'Consórcio Intermunicipal de Saúde 8 de Abril',
      },
      modalidade: {
        id: 5,
        name: 'Dispensa',
      },
      segmentacoes: [
        {
          objetoId: '5f62da27-1fc7-45c0-9aa8-8662f32572e4',
          objeto: 'Prestação de serviços – mão de obra',
          segmentoId: '66072c7f-de22-4fec-a9c5-40bbb62d98b4',
          segmento: 'Manutenção de Veículos',
        },
      ],
      inicioRecebimentoPropostas: '2024-04-04T08:00:00-03:00',
      createdAt: '2024-04-03T17:48:54.434521',
      publishAt: '2024-04-03T18:20:08.498',
      edicaoPosPublicacao: false,
      statusSalaDisputa: {
        id: 2,
        name: 'Não iniciado',
      },
    },
    {
      uniqueId: '41b32254-bc73-41dc-916a-bdfae7e2580c',
      numeroEdital: '014-2024',
      numeroProcesso: '014-2024',
      editalStatus: {
        id: 5,
        name: 'Publicado',
      },
      orgaoPromotor: {
        orgaoPromotorId: '8aab1f9b-c85c-4399-b800-7c65495c5063',
        uniqueId: '8aab1f9b-c85c-4399-b800-7c65495c5063',
        documento: '01612556000100',
        nome: 'Curral Novo do Piauí',
        uf: 'PI',
        razaoSocial: 'Prefeitura Municipal de Curral Novo do Piauí',
        nomeFantasia: 'Curral Novo do Piauí',
        unidadesCompradoras: [],
      },
      unidadeCompradora: {
        unidadeCompradoraId: '8aab1f9b-c85c-4399-b800-7c65495c5063',
        documento: '01612556000100',
        nome: 'Curral Novo do Piauí',
        razaoSocial: 'Prefeitura Municipal de Curral Novo do Piauí',
      },
      modalidade: {
        id: 3,
        name: 'Pregão (Setor público)',
      },
      segmentacoes: [
        {
          objetoId: '14cb59b5-67f1-4e30-9b83-6ea1817e32c7',
          objeto: 'Comunicação/identificação visual',
          segmentoId: '6f73e47b-57ac-49d9-a7cf-6e128528cefc',
          segmento: 'Comunicação Visual e Gráficas',
        },
      ],
      inicioRecebimentoPropostas: '2024-04-04T08:00:00-03:00',
      inicioAnalisePropostas: '2024-04-18T08:01:00-03:00',
      createdAt: '2024-04-03T17:28:46.529186',
      publishAt: '2024-04-03T17:35:02.988',
      edicaoPosPublicacao: false,
      statusSalaDisputa: {
        id: 2,
        name: 'Não iniciado',
      },
    },
    {
      uniqueId: 'e18224e6-20ca-4c42-b6dc-6c623c196825',
      numeroEdital: '014/2024',
      numeroProcesso: '036/2024',
      editalStatus: {
        id: 5,
        name: 'Publicado',
      },
      orgaoPromotor: {
        orgaoPromotorId: 'a06b6bde-499e-45dc-be71-fa1db985e2b8',
        uniqueId: 'a06b6bde-499e-45dc-be71-fa1db985e2b8',
        documento: '20905865000104',
        nome: 'Inhapim',
        uf: 'MG',
        razaoSocial: 'Prefeitura Municipal de Inhapim',
        nomeFantasia: 'Inhapim',
        unidadesCompradoras: [],
      },
      unidadeCompradora: {
        unidadeCompradoraId: 'a06b6bde-499e-45dc-be71-fa1db985e2b8',
        documento: '20905865000104',
        nome: 'Inhapim',
        razaoSocial: 'Prefeitura Municipal de Inhapim',
      },
      modalidade: {
        id: 3,
        name: 'Pregão (Setor público)',
      },
      segmentacoes: [
        {
          objetoId: 'b6b01d45-81ae-47eb-a9eb-e9b767e2b142',
          objeto: 'Instalações',
          segmentoId: '2f59c66e-994e-4d4c-89ca-4cce7b194af4',
          segmento: 'Manutenção em campo de futebol e estádios',
        },
      ],
      inicioRecebimentoPropostas: '2024-04-03T17:00:00-03:00',
      inicioAnalisePropostas: '2024-04-18T09:00:00-03:00',
      createdAt: '2024-04-03T16:48:48.172188',
      publishAt: '2024-04-03T16:55:43.348',
      edicaoPosPublicacao: false,
      statusSalaDisputa: {
        id: 3,
        name: 'Em execução',
      },
    },
    {
      uniqueId: '0d5d4f66-cdb6-47a6-be14-1377c63560a9',
      numeroEdital: '06',
      numeroProcesso: '735/2024',
      editalStatus: {
        id: 5,
        name: 'Publicado',
      },
      orgaoPromotor: {
        orgaoPromotorId: '97d536b0-d4bd-4736-9946-940f34016418',
        uniqueId: '97d536b0-d4bd-4736-9946-940f34016418',
        documento: '46561593000166',
        nome: 'Caixa de Saúde de São Vicente',
        uf: 'SP',
        razaoSocial: 'Caixa de Saúde de São Vicente',
        nomeFantasia: 'Caixa de Saúde de São Vicente',
        unidadesCompradoras: [],
      },
      unidadeCompradora: {
        unidadeCompradoraId: '97d536b0-d4bd-4736-9946-940f34016418',
        documento: '46561593000166',
        nome: 'Caixa de Saúde de São Vicente',
        razaoSocial: 'Caixa de Saúde de São Vicente',
      },
      modalidade: {
        id: 5,
        name: 'Dispensa',
      },
      segmentacoes: [
        {
          objetoId: '18c746e4-2a80-4db3-b671-d9811c1b9916',
          objeto: 'Saúde',
          segmentoId: 'ce23db6d-be9c-440d-9e57-52693b4e6d62',
          segmento: 'Medicamentos',
        },
      ],
      inicioRecebimentoPropostas: '2024-04-04T08:00:00-03:00',
      createdAt: '2024-04-03T16:42:50.037249',
      publishAt: '2024-04-03T17:08:24.665',
      edicaoPosPublicacao: false,
      statusSalaDisputa: {
        id: 2,
        name: 'Não iniciado',
      },
    },
    {
      uniqueId: 'e4355e8e-2753-403f-9737-78ae8caed86e',
      numeroEdital: '13',
      numeroProcesso: '45',
      editalStatus: {
        id: 5,
        name: 'Publicado',
      },
      orgaoPromotor: {
        orgaoPromotorId: 'a886878f-bb23-44aa-b94b-df02633970d1',
        uniqueId: 'a886878f-bb23-44aa-b94b-df02633970d1',
        documento: '17702515000136',
        nome: 'Pref Santana de Cataguases',
        uf: 'MG',
        razaoSocial: 'Prefeitura Municipal de Santana de Cataguases',
        nomeFantasia: 'Pref Santana de Cataguases',
        unidadesCompradoras: [],
      },
      unidadeCompradora: {
        unidadeCompradoraId: 'a886878f-bb23-44aa-b94b-df02633970d1',
        documento: '17702515000136',
        nome: 'Pref Santana de Cataguases',
        razaoSocial: 'Prefeitura Municipal de Santana de Cataguases',
      },
      modalidade: {
        id: 3,
        name: 'Pregão (Setor público)',
      },
      segmentacoes: [
        {
          objetoId: 'e0480944-d883-40f4-bd31-984be69bbd41',
          objeto: 'Engenharia - serviços',
          segmentoId: '2a52236c-0d78-41ba-9176-575e93865055',
          segmento: 'Pavimentação, drenagem',
        },
      ],
      inicioRecebimentoPropostas: '2024-04-03T18:00:00-03:00',
      inicioAnalisePropostas: '2024-04-17T14:01:00-03:00',
      createdAt: '2024-04-03T16:27:47.668353',
      publishAt: '2024-04-03T16:59:31.656',
      edicaoPosPublicacao: false,
      statusSalaDisputa: {
        id: 3,
        name: 'Em execução',
      },
    },
    {
      uniqueId: 'a60d2082-d2c2-472b-a762-5807a5beff1a',
      numeroEdital: '002/2024',
      numeroProcesso: '3605/2024',
      editalStatus: {
        id: 5,
        name: 'Publicado',
      },
      orgaoPromotor: {
        orgaoPromotorId: 'fad3c5e7-9848-4c3b-bfda-d0404dc06105',
        uniqueId: 'fad3c5e7-9848-4c3b-bfda-d0404dc06105',
        documento: '46523080000160',
        nome: 'Franco da Rocha',
        uf: 'SP',
        razaoSocial: 'Prefeitura Municipal de Franco da Rocha',
        nomeFantasia: 'Franco da Rocha',
        unidadesCompradoras: [],
      },
      unidadeCompradora: {
        unidadeCompradoraId: 'fad3c5e7-9848-4c3b-bfda-d0404dc06105',
        documento: '46523080000160',
        nome: 'Franco da Rocha',
        razaoSocial: 'Prefeitura Municipal de Franco da Rocha',
      },
      modalidade: {
        id: 3,
        name: 'Pregão (Setor público)',
      },
      segmentacoes: [
        {
          objetoId: '5967ce5c-cf79-4e94-8f9e-289ddf560eee',
          objeto: 'Combustíveis',
          segmentoId: '5936aafd-1203-4e59-bae3-f2f5014c7b73',
          segmento: 'GLP – botijões',
        },
      ],
      inicioRecebimentoPropostas: '2024-04-03T17:30:00-03:00',
      inicioAnalisePropostas: '2024-04-16T10:01:00-03:00',
      createdAt: '2024-04-03T15:59:51.289725',
      publishAt: '2024-04-03T16:30:54.709',
      edicaoPosPublicacao: false,
      statusSalaDisputa: {
        id: 3,
        name: 'Em execução',
      },
    },
    {
      uniqueId: '9a0e967b-c1fd-4cd7-bd38-8a4a3cc78166',
      numeroEdital: '24/2024',
      numeroProcesso: '24/2024',
      editalStatus: {
        id: 5,
        name: 'Publicado',
      },
      orgaoPromotor: {
        orgaoPromotorId: '82aa11a6-92c8-411f-be73-36bcbc8aaef0',
        uniqueId: '82aa11a6-92c8-411f-be73-36bcbc8aaef0',
        documento: '46634333000173',
        nome: 'São Miguel Arcanjo',
        uf: 'SP',
        razaoSocial: 'Prefeitura do Município de São Miguel Arcanjo',
        nomeFantasia: 'São Miguel Arcanjo',
        unidadesCompradoras: [],
      },
      unidadeCompradora: {
        unidadeCompradoraId: '82aa11a6-92c8-411f-be73-36bcbc8aaef0',
        documento: '46634333000173',
        nome: 'São Miguel Arcanjo',
        razaoSocial: 'Prefeitura do Município de São Miguel Arcanjo',
      },
      modalidade: {
        id: 5,
        name: 'Dispensa',
      },
      segmentacoes: [
        {
          objetoId: '323c4a34-d28e-44aa-8ca1-1bfe571a2b95',
          objeto: 'Especializados',
          segmentoId: '3ba74d4a-d1b8-4a9d-9408-1790b398da21',
          segmento: 'Tratamento de água, análises de água',
        },
      ],
      inicioRecebimentoPropostas: '2024-04-04T08:00:00-03:00',
      createdAt: '2024-04-03T15:50:20.937997',
      publishAt: '2024-04-03T15:58:17.955',
      edicaoPosPublicacao: false,
      statusSalaDisputa: {
        id: 2,
        name: 'Não iniciado',
      },
    },
  ],
  count: 13188,
};
