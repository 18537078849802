export const theme = {
  lightBackground: '#F6F6F6',
  darkBackground: '#000000',
  primary: '#004AA0',
  secondary: '#333333',
  tertiary: '#D9D9D9',
  light: '#FFFFFF',
  dark: '#1E1E1E',
  placeholder: '#E4E4E4',
  overlay: '#000000F2',
  disable: '#C5C5C5',
} as const;
