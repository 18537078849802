import styled from 'styled-components';

export const DetailsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.light};
  max-width: 1440px;
  padding: 24px;
  font-family: Montserrat, sans-serif;

  #card-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: ${({ theme }) => theme.primary};
    border-radius: 4px;
    font-size: 1.7rem;
    font-weight: bold;
    max-width: 16em;
  }
  
  #icon {
    padding: 0.7em 0.7em;
  }

  #bold {
    font-weight: bold;
  }

  #blue {
    color: ${({ theme }) => theme.primary};
    font-size: 2.5rem;
    font-weight: bold;
  }

  .header,
  .row {
    display: flex;
    flex-direction: row;
  }

  .column {
    flex: 1;
    padding: 0.3em 0em;
    text-align: left;
    width: 25em;
  }

  hr {
    width: 100%;
    border: 1px solid;
    margin: 8px 0;
    border-color: #edf1f7;
  }

  .container,
  .main-tab,
  .div-one,
  .div-two,
  .div-three,
  .div-four,
  .div-five,
  .div-six,
  .div-seven,
  .div-eight,
  .div-nine,
  .div-ten,
  .div-eleven,
  .div-twelve,
  .div-thirteen,
  .div-fourteen,
  .div-fifteen,
  .div-sixteen {
    margin-bottom: 16px;
  }

  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .header,
    .row {
      flex-direction: column;
    }

    .column {
      width: 100%;
    }

    #card-title {
      flex-direction: column;
      max-width: 100%;
      font-size: 1.5rem;
    }

    #icon {
      padding: 0.5em 0.5em;
    }

    #blue {
      font-size: 2rem;
    }
  }
`;
