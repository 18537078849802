import axios from 'axios';
import { login } from '../api/EditaisService';

export const TOKEN_STORAGE_KEY = '@bbm:token';

export const api = axios.create({
  baseURL: process.env.API_URL,
});

export const apiAuth = axios.create({
  baseURL: process.env.API_URL,
});

let tokenPromise: Promise<string> | null = null;

const getToken = async () => {
  if (!tokenPromise) {
    tokenPromise = new Promise(async (resolve, reject) => {
      try {
        const { accessToken } = await login({
          username: process.env.API_USER_CREDENTIALS,
          password: process.env.API_PASSWORD_CREDENTIALS,
        });

        if (accessToken) {
          localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);
          resolve(accessToken);
        } else {
          reject(new Error('Failed to get access token'));
        }
      } catch (error) {
        reject(error);
      } finally {
        tokenPromise = null;
      }
    });
  }

  return tokenPromise;
};

api.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem(TOKEN_STORAGE_KEY);

    if (!token) {
      token = await getToken();
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    try {
      if (error?.response?.status === 401 && !originalRequest._retry) {
        console.log('Trying to refresh token');
        originalRequest._retry = true;
        const token = await getToken();
        originalRequest.headers.Authorization = 'Bearer ' + token;
        return axios(originalRequest);
      }
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }

    return Promise.reject(error);
  },
);
