import styled from 'styled-components';

export const HomeContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.light};
  max-width: 1440px;
  display: grid;
  gap: 52px;
  padding: 24px;

  section {
    header {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 24px;
      justify-content: space-between;

      div.titles {
        flex: 1;
        h4 {
          color: ${({ theme }) => theme.primary};
          font-size: 3.8rem;
          line-height: 5.4rem;
          font-weight: bold;
        }

        h6 {
          color: ${({ theme }) => theme.secondary};
          font-size: 2.4rem;
          line-height: 3.6rem;
          font-weight: 400;
        }
      }

      div.newsletter {
        display: flex;
        flex-direction: column;
        gap: 12px;

        span {
          color: ${({ theme }) => theme.primary};
          font-size: 1.6rem;
          font-weight: bold;
        }

        button.register {
          display: grid;
          place-items: center;
          background-color: ${({ theme }) => theme.primary};
          color: ${({ theme }) => theme.light};
          font-size: 1.6rem;
          font-weight: bold;
          padding: 12px 24px;
          border-radius: 5px;
          transition: filter 0.2s ease-in-out;

          &:hover {
            filter: brightness(0.8);
          }
        }
      }
    }
  }

  main {
    background-color: ${({ theme }) => theme.lightBackground};
    border-radius: 12px;
    width: 100%;
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 44px;
    padding-right: 28px;
    padding-bottom: 32px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    aside.filters-bar {
      padding: 16px 24px;
      background-color: ${({ theme }) => theme.primary};
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-radius: 12px;

      form {
        padding-top: 18px;
        width: 100%;
        display: grid;
        gap: 18px;

        button.filter {
          margin-top: 6px;
          width: 100%;
          display: grid;
          place-items: center;
          background-color: ${({ theme }) => theme.light};
          color: ${({ theme }) => theme.primary};
          font-size: 1.6rem;
          font-weight: bold;
          padding: 12px 24px;
          border-radius: 5px;
          transition: filter 0.2s ease-in-out;

          &:hover {
            filter: brightness(0.8);
          }
        }
      }
    }

    aside.editais-participantes {
      margin-top: 32px;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
  }
`;

export const EmptyContainer = styled.div`
  color: #043063;
`;
