import styled from 'styled-components';

export const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.light};
  height: fit-content;
  max-width: 1440px;
  margin: 0 auto;

  img {
    display: block;
    max-width: 100%;
  }

  div.social-media-icons {
    display: flex;
    gap: 24px;
  }
`;
