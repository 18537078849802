import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #111f34;
  text-align: center;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  padding: 20px;
  border-bottom: 1px solid #97979731;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 400;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  border-top: 1px solid #97979731;
  padding-top: 20px;

  > div > span {
    color: #2d2f32;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;
  }
`;
