import { HeaderContainer } from '@components/Header/styles';
import logo from '@assets/logo.svg';
import { SocialMediaButton } from '@components/SocialMediaButton';
import { useTheme } from 'styled-components';
import {
  CaretDown,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  UserCircle,
  YoutubeLogo,
} from '@phosphor-icons/react';
import { useMediaQuery } from '@mui/material';

const socialMedias = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/bbmnet',
    icon: <FacebookLogo />,
    color: 'primary',
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/company/bbmnet-licitacoes',
    icon: <LinkedinLogo />,
    color: 'primary',
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/bbm_net/',
    icon: <InstagramLogo />,
    color: 'primary',
  },
  {
    name: 'Youtube',
    url: 'https://www.youtube.com/channel/UCVpPOVXilOB6Etk-_uXIZ_Q',
    icon: <YoutubeLogo />,
    color: 'primary',
  },
];

export const Header = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  return (
    <HeaderContainer>
      {isSmallScreen ? (
        <img className="logo" src={logo} alt="" width={200} />
      ) : (
        <>
          <a href="https://novobbmnet.com.br">
            <img className="logo" src={logo} alt="" width={250} />
          </a>

          <input type="text" />

          <div className="social-media-icons">
            {socialMedias.map(({ name, url, color, icon }) => (
              <SocialMediaButton
                key={name}
                color={theme[color]}
                name={name}
                url={url}
              >
                {icon}
              </SocialMediaButton>
            ))}
          </div>

          {/* Botão de Login, atualmente sem funcionalidade */}
          {/* <button
            className="login-button"
            style={{
              backgroundColor: '#ffffff',
              borderStyle: 'solid',
              borderColor: '#014B9A',
              borderWidth: '2px',
              padding: '0.5em',
              borderRadius: '4px',
            }}
          >
            <UserCircle weight="fill" color={theme.primary} size={24} />

            <span>Entrar</span>

            <CaretDown />
          </button> */}
        </>
      )}
    </HeaderContainer>
  );
};
