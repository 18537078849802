import styled from 'styled-components';

export const Content = styled.div`
  padding: 12px;
  width: 100%;
  max-width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  color: #333333;
  gap: 3rem;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
  }

  div.column {
    display: flex;
    flex-direction: column;
    gap: 16px;

    h3 {
      color: ${({ theme }) => theme.secondary};
      font-size: 1.6rem;
    }

    a {
      color: white;
      font-size: 1.6rem;
      padding: 12px;
      background-color: ${({ theme }) => theme.primary};
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }
`;

export const CaptchaContent = styled.div`
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const Button = styled.button`
  padding: 12px;
  color: white;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.6rem;
`;
