import { createFileRoute } from '@tanstack/react-router';
import { Home } from '@pages/Home';
import { z } from 'zod';
import { TOKEN_STORAGE_KEY } from '@libs/api';

const editaisSearchSchema = z.object({
  take: z.number().catch(9),
  skip: z.number().catch(0),
  numeroEdital: z.string().optional(),
  modalidadeId: z.number().optional(),
  objetoId: z.string().optional(),
  orgaoPromotorId: z.string().optional(),
  uf: z.string().optional(),
  EditaisEncerrados: z.string().optional(),
  token: z.string().optional(),
});

export const Route = createFileRoute('/')({
  component: Home,
  validateSearch: (search) => editaisSearchSchema.parse(search),
  beforeLoad: async ({ search }) => {
    if (search?.token) {
      localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(search?.token));
    }
  },
});
