import { styled } from 'styled-components';

export const PaginatorContainer = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  button {
    background-color: ${({ theme }) => theme.light};
    font-size: 1.6rem;
    font-weight: bold;
    display: grid;
    place-items: center;
    min-height: 33px;
    min-width: 33px;
    border-radius: 5px;
    color: ${({ theme }) => theme.dark};
    outline: 1px solid ${({ theme }) => theme.tertiary};
    transition: filter 0.2s ease-in-out;

    &.active {
      background-color: ${({ theme }) => theme.tertiary};
    }

    &.navigation {
      background-color: ${({ theme }) => theme.primary};
    }

    &:not(:disabled):hover {
      filter: brightness(0.9);
    }
  }

  button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
