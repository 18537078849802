import React from 'react';
import { Modal, Spin } from 'antd';

import * as Styled from './styles';
import { CustomModalProps } from './types';

const ModalCustom: React.FC<CustomModalProps> = ({
  isOpen,
  setModalState,
  width = '60%',
  loading = false,
  title,
  children,
  footer,
  destroyOnClose = true,
  ...rest
}) => {
  return (
    <Modal
      open={isOpen}
      width={width}
      onCancel={() =>
        setModalState && setModalState((state: boolean) => !state)
      }
      footer={
        footer ? (
          <Styled.FooterContainer>{footer}</Styled.FooterContainer>
        ) : null
      }
      destroyOnClose={destroyOnClose}
      {...rest}
    >
      <Styled.Container>
        {title && (
          <Styled.HeaderContainer>
            <Styled.Title>{title}</Styled.Title>
          </Styled.HeaderContainer>
        )}
        <Spin spinning={loading}>{children}</Spin>
      </Styled.Container>
    </Modal>
  );
};

export default ModalCustom;
