import {
  CalendarIcon,
  MenuIcon,
  EditIcon,
  FolderIcon,
  StampIcon,
  ThreeDotsIcon,
} from '@assets/icons';
import { CardEditalContainer } from '@components/CardEdital/styles';
import { Edital } from '@libs/types';
import { formatDateToLocale } from '@utils/formatDateToLocale';
import { useEffect, useState } from 'react';
import { EditalDetailsModal } from '../EditalDetailsModal';
import { EditalDownloadModal } from '../EditalDownloadModal';
import { getEdital } from '../../api/EditaisService';
type CardEditalProps = {
  edital: Edital;
};

export const CardEdital = ({ edital }: CardEditalProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEditalDetailsOpen, setIsEditalDetailsOpen] = useState(false);
  const [editalCurrent, setEditalCurrent] = useState<Edital | null>(edital);

  const handleOpenEdital = () => {
    setIsOpenModal(true);
  };

  const handleOpenEditalDetails = () => {
    setIsEditalDetailsOpen(true);
  };

  const fetchEditalInfo = () => {
    try {
      getEdital(edital.uniqueId).then((result) => {
        setEditalCurrent(result);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchEditalInfo();
  }, []);

  return (
    <CardEditalContainer>
      <header>
        <div className="titles">
          <span>Nome do Órgão Promotor</span>
          <h6 className="name">{edital?.orgaoPromotor?.nome || '-'}</h6>
        </div>

        <div className="menu-icon">
          <MenuIcon />
        </div>
      </header>

      <section className="infos">
        <div className="row-info">
          <div className="icon">
            <CalendarIcon />
          </div>

          <div className="info">
            <label>Data publicação</label>
            <span>{formatDateToLocale(edital?.publishAt) || '-'}</span>
          </div>
        </div>

        <div className="row-info">
          <div className="icon">
            <CalendarIcon />
          </div>

          <div className="info">
            <label>Termino Recebimento das Propostas</label>
            <span>
              {editalCurrent?.terminoRecebimentoPropostas
                ? formatDateToLocale(editalCurrent?.terminoRecebimentoPropostas)
                : '-'}
            </span>
          </div>
        </div>

        <div className="row-info">
          <div className="icon">
            <StampIcon />
          </div>

          <div className="info">
            <label>Modalidade</label>
            <span>{edital?.modalidade?.name || '-'}</span>
          </div>
        </div>

        <div className="row-info">
          <div className="icon">
            <FolderIcon />
          </div>

          <div className="info">
            <label>Objeto</label>
            <span>{edital?.segmentacoes[0]?.objeto || '-'}</span>
          </div>
        </div>

        <div className="row-info footer">
          <div className="row-info">
            <div className="icon">
              <EditIcon />
            </div>

            <div className="info">
              <label>Edital</label>
              <span>{edital?.numeroEdital || '-'}</span>
            </div>
          </div>

          <div className="row-info">
            <div className="icon">
              <ThreeDotsIcon />
            </div>

            <div className="info">
              <label>Situação</label>
              <span>
                {localStorage.getItem('editaisEncerrados') != null &&
                localStorage.getItem('editaisEncerrados') === 'true'
                  ? 'Encerrado'
                  : 'Em Execução'}
              </span>
            </div>
          </div>
        </div>
      </section>

      <button onClick={handleOpenEditalDetails}>Ver Detalhes</button>
      <footer>
        <button onClick={handleOpenEdital}>Download do Edital</button>
      </footer>

      {isEditalDetailsOpen && editalCurrent && (
        <EditalDetailsModal
          edital={editalCurrent}
          isOpen={isEditalDetailsOpen}
          setEditalInfo={setEditalCurrent}
          onClose={() => setIsEditalDetailsOpen(false)}
        />
      )}

      {isOpenModal && (
        <EditalDownloadModal
          edital={editalCurrent}
          isOpen={isOpenModal}
          setEditalInfo={setEditalCurrent}
          onClose={() => setIsOpenModal(false)}
        />
      )}
    </CardEditalContainer>
  );
};
