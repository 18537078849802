import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  button {
    border: none;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  input {
    border: none;
  }

  html {
    width: 100%;
    font-size: 62.5%;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  body, #root {
    height: 100%;
  }

  button, textarea, strong, span, input {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -moz-osx-font-smoothing: grayscale;
    background: ${({ theme }) => theme.light};
    color: ${({ theme }) => theme.light};
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    body {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 400px) {
    body {
      font-size: 1.2rem;
    }
  }
`;
