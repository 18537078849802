import { TextInputContainer } from '@components/TextInput/styles';
import { InputHTMLAttributes, useId } from 'react';

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

export const TextInput = ({ label, ...rest }: TextInputProps) => {
  const inputId = useId();

  return (
    <TextInputContainer>
      <label htmlFor={inputId}>{label}</label>

      <input type="text" id={inputId} {...rest} />
    </TextInputContainer>
  );
};
