export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        stroke="#004AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.35 5.02H1L5.02 1v3.35c0 .369-.301.67-.67.67zM13.73 10.42v5.32c0 .368-.302.67-.67.67H1.67a.672.672 0 01-.67-.67V6.4M6.36 1h6.7c.368 0 .67.302.67.67v3.35"
      />
      <path
        stroke="#004AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.395 13.22L6.79 14.408l1.18-2.607 5.687-5.681 1.42 1.42-5.68 5.682z"
      />
      <path
        stroke="#004AA0"
        strokeLinejoin="round"
        d="M13.964 8.651l-1.427-1.42M9.388 13.227L7.968 11.8"
      />
    </svg>
  );
};
