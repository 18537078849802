import styled from 'styled-components';

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    font-weight: bold;
    color: ${({ theme }) => theme.light};
    font-size: 1.6rem;
    margin-left: 4px;
  }

  input {
    width: 100%;
    padding: 12px;
    font-size: 1.4rem;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.light};
    border-radius: 5px;
    color: ${({ theme }) => theme.light};
    font-weight: 500;

    &::placeholder {
      font-weight: 500;
      color: ${({ theme }) => theme.light};
    }
  }
`;
