import styled from 'styled-components';

export const SocialMediaButtonContainer = styled.a<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  color: '#ffffff';
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 2.4ch;

  &:hover {
    filter: brightness(90%);
  }
`;
