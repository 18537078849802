import { useState } from 'react';
import { Documento, ModalProps } from './types';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, CaptchaContent, Content } from './styles';
import ModalCustom from '../ModalCustom';
import { getEdital } from '../../api/EditaisService';
import { message } from 'antd';

// const REACT_APP_SITE_KEY = '6LfmybEpAAAAAL1bmZsRHkFVyioXTUuJhsg86MW9';
const REACT_APP_SITE_KEY = '6Le38bQpAAAAACwrxYFZlc6AN8Di_mKHCpgMi91f';

export const EditalDownloadModal = ({
  edital,
  isOpen,
  setEditalInfo,
  onClose,
}: ModalProps) => {
  const [token, setToken] = useState<string | null>();
  const [loading, setLoading] = useState(false);

  const fetchEditalInfo = async () => {
    try {
      const editalInfo = await getEdital(edital.uniqueId);
      setEditalInfo && setEditalInfo(editalInfo);
      return editalInfo;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getDocumento = async (documentos: Documento[]) => {
    try {
      if (!documentos) return null;

      const documento = documentos.find(
        (documento: Documento) => documento.tipoDocumento.name === 'Edital',
      );
      if (documento) {
        console.log('Documento encontrado:', documento);
      } else {
        console.log('Documento não encontrado');
      }
      return documento as Documento;
    } catch (err) {
      console.log(err);
    }
  };

  const getDocumentoLink = async () => {
    try {
      let documentos = edital.documentos as Documento[];

      if (!documentos || documentos.length === 0) {
        const editalInfo = await fetchEditalInfo();
        documentos = editalInfo?.documentos as Documento[];
      }

      const documento = (await getDocumento(documentos)) as Documento;

      return documento?.downloadLink;
    } catch (err) {
      console.log(err);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const documentoLink = await getDocumentoLink();

      if (documentoLink) {
        window.open(documentoLink, '_blank');
      } else {
        console.log('Falha ao obter o link do documento');
        message.error('Falha ao obter o link do documento');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCustom isOpen={isOpen} setModalState={onClose} width="390px">
      <Content>
        <div>
          <h3>Caso queira visualizar o edital, clique abaixo:</h3>
          <CaptchaContent>
            <ReCAPTCHA
              sitekey={REACT_APP_SITE_KEY}
              onChange={(key) => setToken(key)}
            />
          </CaptchaContent>
          <Button disabled={!token && !loading} onClick={handleSubmit}>
            Visualizar Edital
          </Button>
        </div>
        {/* <hr />
        <div>
          <div className="column">
            <h3>Caso já tenha credenciais para acesso ao BBMNET</h3>

            <a
              href={`https://sistema.novobbmnet.com.br/visaoeditais/editais/editar/${edital.uniqueId}?visualizar=true`}
              target="_blank"
              rel="noreferrer noopener"
            >
              Clique Aqui
            </a>
          </div>

          <hr />

          <div className="column">
            <h3>Caso queira acessar sem cadastro, siga o processo abaixo</h3>

            <a
              href="https://novobbmnet.com.br/sociedade-cidadao"
              target="_blank"
              rel="noreferrer noopener"
            >
              Sem Cadastro
            </a>
          </div>
        </div> */}
      </Content>
    </ModalCustom>
  );
};
