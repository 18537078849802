import React, { useEffect, useState } from 'react';
import { getEdital } from '@api/EditaisService';
import { EditalDetailsModal } from '@components/EditalDetailsModal';
import { Edital } from '@libs/types';
import { Spin } from 'antd';

export const Edital = () => {
  const id = window.location.href.split('edital/')[1];
  const [edital, setEdital] = useState<Edital | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEdital(id).then((data) => {
      setEdital(data);
      setLoading(false);
    });
  }, [id]);

  if (loading) {
    return <Spin spinning={loading}>Carregando...</Spin>;
  }

  if (!edital) {
    return <div>Edital não encontrado</div>;
  }

  return <EditalDetailsModal edital={edital} />;
};
