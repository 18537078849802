import React, { useCallback, useEffect, useState } from 'react';
import { EmptyContainer, HomeContainer } from '@pages/Home/styles';
import { EditaisParticipantes } from '@libs/types';
import { getEditais, getDadosSuporte } from '@api/EditaisService';
import { CardEdital } from '@components/CardEdital';
import { Paginator } from '@components/Paginator';
import { TextInput } from '@components/TextInput';
import { Select } from '@components/Select';
import { states } from '@utils/states';
import { editaisEmptyMock } from '../../utils/editaisMock';
import { Header } from '@components/Header';
import { Spin } from 'antd';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { segments } from '@pages/Home/segments';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FlaskIcon } from '@assets/icons/flask';

export type PaginationType = {
  take: number;
  skip: number;
};

export type Option = {
  label: string;
  value: string;
};

type SelectOptions = {
  estado: Option[];
  modalidade: Option[];
  orgaoPromotor: Option[];
  EditaisEncerrados: Option[];
  objeto: Option[];
  segmento: Option[];
};

const PAGINATION: PaginationType = {
  take: 9,
  skip: 0,
};

export const Home = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [participantes, setParticipantes] =
    useState<EditaisParticipantes | null>(null);
  const [allParticipantes, setAllParticipantes] =
    useState<EditaisParticipantes | null>(null);
  const [selectOptions, setSelectOptions] = useState<SelectOptions>({
    estado: states,
  } as SelectOptions);
  const [search, setSearch] = useState({
    edital: '',
    keyword: '',
    nomeOrgao: '',
    segmento: '',
    estado: '',
    modalidade: '',
    orgaoPromotor: '',
    EditaisEncerrados: '',
    objeto: '',
  });
  const [orgaoSuggestions, setOrgaoSuggestions] = useState<string[]>([]);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const handleSelectOption = (value: string | number, name: string) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleGetSupport = () => {
    getDadosSuporte().then((dadosSuporte) => {
      const { modalidade, ramoAtividade } = dadosSuporte;
      if (modalidade) {
        const modalidadeOptions = modalidade.map((_modalidade: any) => ({
          label: _modalidade.name,
          value: _modalidade.id,
        })) as unknown as Option[];
        setSelectOptions((prevState) => ({
          ...prevState,
          modalidade: modalidadeOptions,
        }));
      }
      const situacaoOptions = [
        {
          label: 'Em execução',
          value: 'false',
        },
        {
          label: 'Encerrado',
          value: 'true',
        },
      ] as unknown as Option[];
      setSelectOptions((prevState) => ({
        ...prevState,
        EditaisEncerrados: situacaoOptions,
      }));
      if (ramoAtividade) {
        const objetoOptions = ramoAtividade.map((objeto: any) => ({
          label: objeto.descricao,
          value: objeto.uniqueId,
        }));
        setSelectOptions((prevState) => ({
          ...prevState,
          objeto: objetoOptions,
        }));
      }
    });
  };

  const handleFilter = useCallback(
    (event?: React.FormEvent<HTMLFormElement>) => {
      event && event.preventDefault();
      let queryString = `?skip=0&take=99999999`;
      localStorage.setItem('editaisEncerrados', 'false');

      if (search.modalidade) {
        queryString += `&modalidadeId=${search.modalidade}`;
      }
      if (search.edital) {
        queryString += `&numeroEdital=${search.edital}`;
      }
      if (search.objeto) {
        queryString += `&objetoId=${search.objeto}`;
      }
      if (search.orgaoPromotor) {
        queryString += `&orgaoPromotorId=${search.orgaoPromotor}`;
      }
      if (search.EditaisEncerrados && search.EditaisEncerrados === 'true') {
        queryString += `&EditaisEncerrados=${search.EditaisEncerrados}`;
        localStorage.setItem('editaisEncerrados', 'true');
      }
      if (search.estado) {
        queryString += `&uf=${search.estado}`;
      }

      setLoading(true);
      getEditais(queryString)
        .then((result) => {
          let resultFiltered = result.editais;

          if (search.nomeOrgao) {
            resultFiltered = resultFiltered.filter((item: any) =>
              item.orgaoPromotor.nome
                .toLowerCase()
                .startsWith(search.nomeOrgao.toLowerCase()),
            );
          }

          if (search.keyword) {
            resultFiltered = resultFiltered.filter(
              (item: any) =>
                item.segmentacoes.filter(
                  (i2: any) =>
                    i2.segmento
                      .toLowerCase()
                      .startsWith(search.keyword.toLowerCase()) ||
                    i2.objeto
                      .toLowerCase()
                      .startsWith(search.keyword.toLowerCase()),
                ).length >= 1,
            );
          }

          if (search.segmento) {
            resultFiltered = resultFiltered.filter(
              (item: any) =>
                item.segmentacoes.filter(
                  (i2: any) => i2.segmentoId === search.segmento,
                ).length >= 1,
            );
          }

          setAllParticipantes({
            editais: resultFiltered,
            count: Math.ceil((resultFiltered.length ?? 1) / PAGINATION.take),
          });
          setParticipantes({
            editais: resultFiltered.slice(0, PAGINATION.take),
            count: Math.ceil((resultFiltered.length ?? 1) / PAGINATION.take),
          });
        })
        .catch((error) => {
          console.log('Falha ao obter os editais. Tente mais tarde!');
          setAllParticipantes(editaisEmptyMock);
          setParticipantes(editaisEmptyMock);
          console.log('error______________', error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [search],
  );

  const handleOrgaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) return;
    const nomeOrgao = event.currentTarget.value;
    setSearch({ ...search, nomeOrgao });

    getEditais(`?skip=0&take=999999999`).then((result) => {
      const orgaoNames = result.editais
        .map((edital: any) => edital.orgaoPromotor.nome)
        .filter((item: any, pos: any, self: string | any[]) => {
          return (
            self.indexOf(item) === pos &&
            item.toLowerCase().startsWith(nomeOrgao.toLowerCase())
          );
        });
      setOrgaoSuggestions(orgaoNames);
    });
  };

  useEffect(() => {
    handleGetSupport();
    handleFilter();

    getEditais(`?skip=0&take=999999999`).then((result) => {
      const orgaoNames = result.editais
        .map((edital: any) => edital.orgaoPromotor.nome)
        .filter((item: any, pos: any, self: string | any[]) => {
          return self.indexOf(item) === pos;
        });
      setOrgaoSuggestions(orgaoNames);
    });
  }, []);

  const handlePageChange = (page: PaginationType) => {
    if (
      page.skip === Math.ceil((participantes?.count ?? 1) - 1 / PAGINATION.take)
    )
      return;

    setCurrentPage(page.skip);
    if (allParticipantes) {
      const startIndex = page.skip * page.take;
      const endIndex = startIndex + page.take;
      setParticipantes({
        editais: allParticipantes.editais.slice(startIndex, endIndex),
        count: allParticipantes.count,
      });
    }
  };

  return (
    <HomeContainer>
      <Header />
      <section>
        <header>
          <div className="titles">
            <h4>Jornal do Licitante</h4>
            <h6>Editais Publicados</h6>
          </div>
          {!isSmallScreen && (
            <div className="newsletter">
              <span>Quer receber os Editais por e-mail?</span>
              <a href="https://novobbmnet.com.br/cadastro-jornal/">
                <button className="register">Cadastre aqui</button>
              </a>
            </div>
          )}
        </header>
      </section>
      <main style={{ gap: isSmallScreen ? '0px' : '44px' }}>
        {isSmallScreen && (
          <span
            onClick={toggleFilters}
            style={{
              display: 'flex',
              justifyContent: 'start',
              gap: '0.7em',
              height: '100%',
              backgroundColor: '#004AA0',
              padding: '1em',
              width: '70%',
              borderRadius: filtersOpen ? '0px' : '8px',
            }}
          >
            <div style={{ width: '20px', color: 'white' }}>
              <FlaskIcon />
            </div>
            <h2>{filtersOpen ? 'Esconder Filtros' : 'Filtros'}</h2>
          </span>
        )}
        {(filtersOpen || !isSmallScreen) && (
          <aside
            className="filters-bar"
            style={{ borderRadius: isSmallScreen ? '0px' : '12px' }}
          >
            <form onSubmit={handleFilter}>
              <strong>Nome do Orgão</strong>
              <Autocomplete
                options={orgaoSuggestions}
                getOptionLabel={(option) => option}
                onInputChange={handleOrgaoChange}
                value={search.nomeOrgao}
                onChange={(event, newValue) =>
                  setSearch({ ...search, nomeOrgao: newValue ?? '' })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Nome do Orgão"
                    variant="outlined"
                    sx={{
                      '& .MuiInputBase-input': {
                        color: 'white', // Texto branco
                        fontSize: '1.6rem',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white', // Bordas brancas
                        },
                        '&:hover fieldset': {
                          borderColor: 'white', // Bordas brancas no hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white', // Bordas brancas quando focado
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: 'white', // Cor do label
                        fontSize: '1.6rem',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'white', // Cor do label quando focado
                        fontSize: '1.6rem',
                      },
                    }}
                  />
                )}
              />

              <TextInput
                onChange={(event) =>
                  handleSelectOption(event.currentTarget.value, 'edital')
                }
                value={search.edital}
                label="Número do Edital"
                placeholder="Número do Edital"
              />
              <Select
                label="Estado"
                onChange={(value) => handleSelectOption(value, 'estado')}
                value={search.estado}
                options={selectOptions?.estado ?? []}
                placeholder="Selecione"
              />
              <Select
                label="Modalidade"
                onChange={(value) => handleSelectOption(+value, 'modalidade')}
                value={search.modalidade}
                options={selectOptions?.modalidade ?? []}
                placeholder="Selecione"
              />
              <Select
                label="Situação"
                onChange={(value) =>
                  handleSelectOption(value, 'EditaisEncerrados')
                }
                value={search.EditaisEncerrados}
                options={selectOptions?.EditaisEncerrados ?? []}
                placeholder="Selecione"
              />
              <Select
                label="Objeto"
                onChange={(value) => handleSelectOption(value, 'objeto')}
                value={search.objeto}
                options={selectOptions?.objeto ?? []}
                placeholder="Selecione"
              />
              <Select
                label="Segmento"
                onChange={(value) => handleSelectOption(value, 'segmento')}
                value={search.segmento}
                options={segments}
                placeholder="Selecione"
              />
              <TextInput
                onChange={(event) =>
                  handleSelectOption(event.currentTarget.value, 'keyword')
                }
                value={search.keyword}
                label="Palavra Chave"
                placeholder="Palavra Chave"
              />
              <button className="filter" type="submit">
                Filtrar
              </button>
              <button
                className="filter"
                onClick={() => {
                  setSearch({
                    edital: '',
                    keyword: '',
                    nomeOrgao: '',
                    segmento: '',
                    estado: '',
                    modalidade: '',
                    orgaoPromotor: '',
                    EditaisEncerrados: '',
                    objeto: '',
                  });
                }}
              >
                Limpar
              </button>
            </form>
          </aside>
        )}
        <Spin spinning={loading}>
          {loading && <h1 style={{ color: 'black' }}>Carregando...</h1>}
          <aside className="editais-participantes">
            {participantes &&
              participantes?.editais.map((edital) => (
                <CardEdital key={edital.uniqueId} edital={edital} />
              ))}
            {!participantes ||
              (participantes?.editais?.length === 0 && (
                <EmptyContainer>
                  <p className="no-results">Nenhum resultado encontrado.</p>
                </EmptyContainer>
              ))}
          </aside>
        </Spin>
      </main>
      <footer>
        <Paginator
          onPageChange={handlePageChange}
          currentPage={currentPage}
          totalPages={participantes?.count ?? 1}
          take={PAGINATION.take}
        />
      </footer>
    </HomeContainer>
  );
};
