import { PropsWithChildren } from 'react';
import * as S from './styles';

type SocialMediaButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren & {
    color: string;
    name: string;
    url: string;
  };

export const SocialMediaButton = ({
  color,
  name,
  url,
  children,
}: SocialMediaButtonProps) => {
  return (
    <S.SocialMediaButtonContainer
      color={color}
      aria-label={name}
      href={url}
      target="_blank"
    >
      {children}
    </S.SocialMediaButtonContainer>
  );
};
