export const segments = [
  {
    value: '3f85c036-ede6-46ba-95c5-0bed736a696a',
    label: 'Asfálticos, concreto',
  },
  {
    value: '5bd0c1d3-bb76-4ebe-b14e-17c521299500',
    label:
      'Saneamento, esgoto, dutos, rede de distribuição de água, rede de gás',
  },
  {
    value: '0859ebf4-ed2e-491c-8c60-d014757ccb28',
    label:
      'Manutenção, peças e pneus, veículos, caminhões, ônibus, ambulâncias, motos',
  },
  {
    value: '80b53bdc-d6ef-4de2-a35f-9d83bc0128f5',
    label: 'Inseticida, herbicida, fertilizantes e outros Agroquímicos',
  },
  {
    value: '7862e6ca-7341-48ab-80e1-ecc2d4e70772',
    label: 'Serviços de terraplanagem',
  },
  {
    value: 'd9c12f7c-533c-4447-b7be-7eacdf7d5cb4',
    label:
      'Manutenção predial, hidráulica, hidro-sanitárias, elétrica, pisos, pintura, telhados',
  },
  {
    value: '779679bb-9a0c-4268-91b8-ac39bed8f820',
    label:
      'Materiais, produtos e utensílios médico, hospitalar e laboratorial',
  },
  { value: '8b6227dd-4256-4b72-b81e-fc56451b9332', label: 'Obra' },
  {
    value: '1ea60755-b286-4ba2-9e54-ec780ef8500d',
    label:
      'Contratação de Empresa Especializada para atuar como Verificador Independente',
  },
  {
    value: '45ae04c6-7891-40b5-b380-959711e9ee17',
    label: 'Manutenção de Rede Semafórica',
  },
  {
    value: '43db2683-c18e-4726-abd1-df05fb859ec3',
    label: 'Controle de Pragas e Insetos',
  },
  {
    value: '76cb6d27-1a47-4f9f-9730-b2ae649682fb',
    label:
      'Copiadora, reprografia, encadernação, leitora, , duplicador, plastificação',
  },
  { value: '1fee77c5-1bf3-4569-ae8e-8fd52c8eedbd', label: 'Psicólogo' },
  {
    value: 'ce23db6d-be9c-440d-9e57-52693b4e6d62',
    label: 'Medicamentos',
  },
  {
    value: 'c8ebdc9f-a697-406d-bb7d-f7a50d7b7c9a',
    label: 'Equipamentos, Suprimentos e Periféricos',
  },
  {
    value: '91cd9840-de4a-44f8-b3af-33d2842a1794',
    label: 'Médico, laboratorial, cadeira de rodas',
  },
  {
    value: '59262fb4-5529-4476-8e6e-37d0f388fd50',
    label: 'Materiais Permanentes',
  },
  {
    value: 'f36c1215-fe9d-4873-85e5-28f5c1fc5f2c',
    label: 'Equipamentos e periféricos, compra, instalação e locação',
  },
  {
    value: '71a0b2d1-2ee4-4fc5-8927-52dfc94694d3',
    label: 'Impressora Térmica e seus Insumos',
  },
  {
    value: '7c40a483-b77b-4e60-98a6-6c9e54c24555',
    label: 'Fornecimento de alimentação, copa, buffet e café',
  },
  {
    value: 'a1104de8-6898-48bb-957f-1758c4936c39',
    label: 'Consultoria, Assessorias técnicas e de apoio, cobranças',
  },
  {
    value: '4a6c3b3c-d9e2-4a95-bce7-461c06c15b28',
    label: 'Testes Rápidos',
  },
  {
    value: '46c87185-4c97-4282-8b62-cf29ddbf65fa',
    label: 'Projetos de engenharia e arquitetura, maquetes',
  },
  {
    value: 'c845b2d3-ad5d-4a4f-99f8-6267bd71c547',
    label: 'Arbitragem',
  },
  {
    value: 'bd08a7bc-3264-40c6-9e32-091dfa96c476',
    label: 'Limpeza de Fossa',
  },
  {
    value: 'a4521ff9-7aaa-48aa-95cf-7be1fd59feac',
    label: 'Sinalização viária',
  },
  {
    value: 'cccfffd6-fd5d-4052-887b-41b8f7cbb179',
    label: 'Vidraçaria, marcenaria, serralheria',
  },
  {
    value: 'dc8eb562-1c53-48a4-9963-78e3f10afd29',
    label: 'Água, bebidas',
  },
  {
    value: '5936aafd-1203-4e59-bae3-f2f5014c7b73',
    label: 'GLP – botijões',
  },
  {
    value: '729ec9f1-be71-451f-b3c8-617fea500943',
    label: 'Lona e Adesivo',
  },
  {
    value: '79cf7015-1c9f-49b8-a198-6148c693d7b9',
    label: 'Papelaria e Sacolas',
  },
  {
    value: 'ed29dc8b-34e5-4a0c-9e4d-090655233d5b',
    label: 'Câmeras de Monitoramento',
  },
  {
    value: '967243d0-9801-4093-909a-5d5182d490ff',
    label: 'Softwares, sistemas, serviços de rede, serviços técnicos',
  },
  {
    value: '1a958a24-9d0b-4324-9919-6ad43b2de59c',
    label: 'Material de Sinalização',
  },
  {
    value: 'bc0b6b15-d402-421c-a711-0980516e170b',
    label: 'Aquisição de veículos, caminhões, ônibus, ambulâncias, motos',
  },
  {
    value: '680c23c4-3a8f-4fb8-9cd4-ed5753f92c09',
    label: 'Serviços gráficos, impressos e edições',
  },
  {
    value: '2a52236c-0d78-41ba-9176-575e93865055',
    label: 'Pavimentação, drenagem',
  },
  {
    value: 'c6df2502-50c6-4c5a-bce5-10d6e3308d65',
    label: 'Serviços de vigilância, armada e desarmada',
  },
  {
    value: 'a2b15b4d-34fb-4453-b577-2517f2d261bc',
    label: 'Materiais Minerais (Pedras)',
  },
  {
    value: '7161d0dc-71a5-4974-b0ea-a785d674d62e',
    label: 'Eletrodomésticos, ventilador, bebedouro',
  },
  {
    value: 'cde056e6-8a8b-47df-8a1e-56d3b6984ede',
    label: 'Mobiliários diversos',
  },
  {
    value: 'fb1a8b9a-708f-4032-b654-dd403fb4afa3',
    label: 'Odontológicos',
  },
  {
    value: '5c417d5b-938e-4216-adac-d42096a08dec',
    label: 'Filmes, raio -x, radiológicos',
  },
  {
    value: '8c8463d5-f289-4d27-a049-60c228a81b83',
    label: 'Brinquedos, material infantil, playground',
  },
  {
    value: 'bd8db2bb-fbe7-4275-8c5c-565697f49034',
    label: 'Serviço de Protocolo e Gerenciamento de Processos Digitais\n',
  },
  {
    value: 'c18b8b4d-580f-497e-a9ff-35b8e86b7122',
    label: 'Órtese e Prótese',
  },
  { value: '1443db51-dd7b-437d-9de5-8d68eff7fd9c', label: 'Elétricos' },
  {
    value: '9d66e3d9-b6e2-4802-a646-6f40610718cb',
    label: 'Material de Construção em Geral',
  },
  { value: '6c1699f5-fc7f-4799-830b-e4558135c101', label: 'Exames' },
  {
    value: '56cc19b4-2c43-4fbf-b010-f2c11376975f',
    label: 'Produtos Descartáveis',
  },
  { value: 'c74117f3-a747-4825-9207-2a2a968c932b', label: 'Hotelaria' },
  {
    value: '123ab488-a173-4034-bac9-ef482ea55e12',
    label: 'Organização de eventos',
  },
  {
    value: '58ae9ec6-aa87-49c4-9ba4-cd05de97ba18',
    label: 'Gêneros alimentícios',
  },
  {
    value: '2a1395e9-aeba-4c0c-a9aa-9c2827cff4e0',
    label: 'Uniformes e confecções em geral',
  },
  {
    value: 'f9d0b9e2-16b4-4477-b16e-45b91b5d2ccb',
    label: 'Software de Inteligência e Gestão de Dados e Informações',
  },
  {
    value: '4d98a1f4-fb26-4181-bc6d-c08d4f0f1b53',
    label: 'Locação de Painel de Led e TVs',
  },
  {
    value: 'e44dceaa-04f3-44c6-8625-f4000518f114',
    label: 'Hidráulicos, compressores e motores, bombas e outros',
  },
  {
    value: '7fa805cc-95ed-4edf-9d8c-a33c51398bdb',
    label: 'Oxigênio Medicinal',
  },
  {
    value: 'ef2cfd9b-6995-49b5-a1ac-7a3acec29f6c',
    label: 'Ferramentas e bancadas',
  },
  {
    value: 'd033f98c-a5d2-4ad0-a9aa-09fab0008bae',
    label: 'Combustíveis e Lubrificantes',
  },
  {
    value: '79d0c2a7-5674-4812-b592-755990ce4865',
    label: 'Serviços de telefonia',
  },
  { value: '127d0f04-c4d5-46cf-aa72-d19e09f2269b', label: 'Internet' },
  {
    value: 'e49bc56f-1852-49cd-8d52-05fe93830abf',
    label: 'Transporte Municipal',
  },
  {
    value: '2ddb8715-d468-44c6-ba3d-534fa2a7634e',
    label:
      'Serviços técnicos, topográficos, sondagens, fundações, levantamentos estudos, saneamento ambiental, georreferenciados, inspeções',
  },
  { value: 'e1cbca81-0297-42fc-95ec-764b282aee79', label: 'Grama' },
  {
    value: '53821126-e116-4376-bbe9-3cacb5f3f8d7',
    label: 'Escritório',
  },
  {
    value: '9aa1ec33-3848-4a3e-96b0-300302e0e4a9',
    label: 'Materiais de Pintura',
  },
  {
    value: '8ef13c1e-43b7-4b19-942d-8f562e203b2d',
    label: 'Hidráulicos, saneamento',
  },
  {
    value: 'a8d9742e-00b0-4b16-abf1-c24a8214828f',
    label:
      'Químicos e produtos para tratamento de água e produtos especiais',
  },
  {
    value: '0b8603e2-c232-4da0-943b-81b0454afaf3',
    label: 'Carroceria, guinchos, guindastes',
  },
  {
    value: '0d8a2839-ee11-4f7f-8528-ed3a08b90338',
    label: 'Cesta básica',
  },
  {
    value: '077b9b3d-3258-4a30-b221-d9248f714db5',
    label: 'Produtos de higiene, limpeza e higiene pessoal',
  },
  {
    value: 'a27bfd55-d0d7-4c49-b09b-08d3857f0849',
    label: 'Pontes, obras de arte, viadutos, túneis, passarelas',
  },
  {
    value: '511e91ea-8141-40a1-9333-6e8cdd948ece',
    label: 'Hidrômetros',
  },
  {
    value: 'c2fe7c00-b229-4740-99f2-2075b73025dd',
    label:
      'Prestação de Serviços de Gestão, Remoção, Depósito e Guarda de Veículos Automotores',
  },
  {
    value: '3ffbbd74-36f1-4533-a73b-4e44d5032c08',
    label: 'Manutenção de Sistema de Combate a Incêndio',
  },
  {
    value: '087ba639-8ca5-48ce-a695-a589dfd80e4c',
    label:
      'Equipamentos e materiais esportivos, instrumentos musicais, artes',
  },
  {
    value: '5ea8700b-8244-4d1c-9c44-ae65ca148378',
    label: 'Poços tubulares, artesianos, perfuratriz, mineração, escavação',
  },
  {
    value: '35f3cea6-512d-4b08-873d-01f70b5a8d3f',
    label: 'Agricultura – Produção Rural',
  },
  {
    value: '4dcfc01f-8cb8-4116-8a34-04caa51a13b4',
    label: 'Construção, madeiras',
  },
  { value: '6035743a-3f66-4d65-9234-e75767894396', label: 'Fraldas' },
  {
    value: 'fd67df75-38ec-43ec-9668-7aabf09b02db',
    label: 'Transporte de cargas: terrestre/aéreo/marítimo',
  },
  {
    value: 'abc92b46-fdb4-42d5-b125-b90e65fa75be',
    label: 'Reformas, edificações e demolição',
  },
  {
    value: '31b0e352-e28a-434d-ab68-ed1061f6d040',
    label: 'Elétrica, redes, isolação, serviços',
  },
  {
    value: 'a3034c49-a5bb-4b0d-92f0-9318315ca80f',
    label: 'Aquisição de Pneus, Peças e Acessórios Automotivos',
  },
  {
    value: '5085f9b8-4486-4e5f-ae07-991d6031bba5',
    label: 'Produções Audiovisuais',
  },
  {
    value: '6f73e47b-57ac-49d9-a7cf-6e128528cefc',
    label: 'Comunicação Visual e Gráficas',
  },
  {
    value: '8ea7a09c-9007-48b8-9803-dfefa1f7bf49',
    label: 'Construção Campo de Futebol',
  },
  {
    value: 'cce137fd-11c6-4c6f-90b8-c3c28e5fa599',
    label: 'Manutenção Preventiva e Corretiva',
  },
  {
    value: 'b7493fcd-82f9-469f-a8ad-1b4d202b6318',
    label: 'Locações Diversas para Realização de Eventos',
  },
  {
    value: 'd64e2daf-6de9-4f09-a7ed-7d98ba88a900',
    label: 'Equipamento de Proteção Individual - EPI',
  },
  {
    value: 'abd14dbf-cf53-48f6-b8ed-3e470922437a',
    label: 'Equipamentos e produtos de proteção individual',
  },
  {
    value: '9c1b243b-cebd-4809-aa6d-94c9485cf01a',
    label: 'Livros e material didático',
  },
  {
    value: 'a7bdd78e-1851-404f-a129-4553c8c21c02',
    label: 'Agrícolas, implementos',
  },
  {
    value: '60fcc45b-1217-4b6b-b3f9-cd7e9c364615',
    label: 'Trator Cortador de Grama',
  },
  {
    value: '16565948-461b-45fb-bcf5-1d9c0c660ad4',
    label: 'Relógio de ponto e controle de acesso',
  },
  { value: 'd0c1358a-ad71-467a-99d0-01b12c2d1381', label: 'Tendas' },
  {
    value: '2adb846c-0a61-4e60-9cc9-9fea8e33e685',
    label: 'Locação de Palcos e Estruturas',
  },
  {
    value: '077985ed-b2be-45c7-9f38-aee167f670a8',
    label: 'Sonorização',
  },
  {
    value: '5607191f-5d49-4fbb-8eae-424b60f75c7f',
    label: 'Leite e Suplementos',
  },
  {
    value: '278ebd91-447a-416e-becb-bc2f52a1e9e0',
    label: 'Urnas, funerários, artigos religiosos',
  },
  { value: 'ea0b37df-76f4-4808-b295-c3a195b5ea85', label: 'Escolar' },
  {
    value: 'cc38f0b5-9847-457a-a63c-1c6d43199635',
    label: 'Papelarias em geral',
  },
  {
    value: '55028116-489c-461c-884c-9c75101bd537',
    label:
      'Estrutura metálica, andaimes, palco, arquibancadas, abrigos, toldos',
  },
  {
    value: '384a18dc-06c1-4db7-8bb3-ae5dec67c37f',
    label: 'Embalagens e lacres em geral',
  },
  {
    value: '633a62d4-51a1-4f89-b16f-87665f5d2988',
    label: 'Prestação de Serviços Bancários',
  },
  {
    value: '98190308-5e76-49c5-9750-a7b08174ba14',
    label: 'Merenda Escolar',
  },
  { value: 'f29323d3-344b-48ed-b4c7-fb7f5ab2ce3c', label: 'Tecidos' },
  {
    value: '9b0a96eb-c065-4829-b9b7-d920931702bc',
    label: 'Roço de Camada Vegetal em Acostamento',
  },
  {
    value: '679ab94d-1bc2-4907-acf8-b1ae2e59e163',
    label: 'Oftalmológico',
  },
  {
    value: '66072c7f-de22-4fec-a9c5-40bbb62d98b4',
    label: 'Manutenção de Veículos',
  },
  {
    value: '3a2b97c8-1aa4-4542-baa1-4a57717698a4',
    label: 'Ar condicionado, limpeza de dutos',
  },
  {
    value: '8663d0cf-c929-4c16-84ab-b2b58472b98a',
    label: 'Equipamentos e Materiais de Laboratório',
  },
  {
    value: '852f348f-18d5-4dba-85f1-54fc85d8ab3a',
    label: 'Smartphone',
  },
  {
    value: '92abec82-0e3d-4aa6-b78d-593fdccacb5c',
    label: 'Exportação de Produtos Agropecuários',
  },
  {
    value: '7525eaa7-fb17-4d30-85ee-33ea9b3c400a',
    label:
      'Prestação de serviço de higienização, manutenção e troca de peças.',
  },
  {
    value: '0e334c5d-f78a-4628-92f9-afd4dbf110a0',
    label: 'Pão Francês com Manteiga',
  },
  { value: '28b848e9-d216-488e-861d-3056c1679cfb', label: 'Seguros' },
  {
    value: '28c95249-65c1-42df-9467-a3582590bd2d',
    label: 'Bombeiro Civil',
  },
  {
    value: '20e8fd03-ffdb-47ec-97a3-cd2b0d02a730',
    label: 'Limpeza, conservação, predial, hospitalar e higienização',
  },
  {
    value: '83efe94c-d9ea-4e01-b128-1620a11ec46b',
    label: 'Energia: geradores, transformadores, painéis e outros',
  },
  {
    value: '274b8790-423e-4eaa-9312-0a7347156b04',
    label:
      'Realização de Programa de Formação Continuada para a Rede de Ensino',
  },
  {
    value: 'edfb127d-4e74-4062-bb37-d25c13ab9065',
    label:
      'Contratação de empresa especializada em prestação serviço de atendimento multiprofissional terapêutico educacional',
  },
  {
    value: '71f1bac1-dc04-457d-a228-903b5c0719a6',
    label: 'Passagens / Coordenação / Tradutor / Guia',
  },
  {
    value: 'd15b0735-319e-45f1-b3ed-39cee736e2ff',
    label: 'Máquinas pesadas',
  },
  {
    value: '8405d20e-c468-4f3e-aaa7-225fcc3b8f44',
    label: 'Serviços, peças e equipamentos',
  },
  {
    value: 'b523be29-ad7e-40ef-85bf-38db53e8b69a',
    label: 'Materiais de escritório, expediente, papéis, formulários',
  },
  {
    value: '431a61c1-d220-4394-837e-5809be9228c1',
    label: 'Aparelhos de Ar Condicionado',
  },
  {
    value: 'bbe93500-39b0-4195-bec5-949d6933bf7e',
    label: 'Equipamentos Maquinário e Bens Públicos',
  },
  {
    value: '741bf9cc-cf66-43d0-9308-74bfbf04a61f',
    label: 'Instalação de Poste Padrão',
  },
  {
    value: 'efc45449-3b3b-4e85-ac31-7e110a207cc6',
    label: 'Muro de arrimo, contenções',
  },
  {
    value: '655cddb7-2dfe-4590-9858-99e90e151de6',
    label: 'Gestão de Documentos',
  },
  {
    value: 'e5694cf1-0602-4f56-925e-631f9e9288a1',
    label: 'Aviamentos',
  },
  {
    value: '2d8f34dc-cb6f-4c7f-b903-3363148bd7c8',
    label: 'Publicações, divulgação, clipping, radiodifusão, assinaturas',
  },
  {
    value: '930eb91e-0283-4c8a-b225-2f79b447bde9',
    label: 'Cama, mesa e banho',
  },
  {
    value: 'cdd0ae9a-a5e1-44fb-9ef8-596f4ac0a7d1',
    label: 'Nebulização Contra Dengue',
  },
  {
    value: 'cceb3e62-6db2-4d88-87eb-7c1bf13c8ae8',
    label: 'Itens de Metalurgia, Vidraçaria e Gradil',
  },
  { value: 'ac4245ba-f4bd-48d4-a352-b92bc419ebfb', label: 'Cursos' },
  {
    value: '01cb026c-dff3-478e-a29e-00063185726d',
    label: 'Aquisição de Extintores de Incêndio, Acessórios e Recarga',
  },
  {
    value: 'c2c06681-1aaf-410d-a4ce-7c4305163c67',
    label: 'Frutas e Verduras',
  },
  {
    value: '48f61e42-783a-45f0-8c02-e9a4d8fe1808',
    label:
      'Clínica Especializada no Tratamento de Pessoas com Transtornos Mentais',
  },
  {
    value: '25f4155a-72ff-4dee-a1e6-a3f17fcb6080',
    label: 'Manuseio, tratamento de documentos, distribuição e entrega',
  },
  {
    value: 'e455a11d-6766-4c32-92a2-e7d0db1e321f',
    label: 'Serviços de locação de veículos, motos',
  },
  {
    value: 'dfad2bcb-a2d1-40c9-9a73-5a363158e13f',
    label: 'Construção/Reformas de Praças',
  },
  {
    value: '1ce9d51e-3ff3-47a8-940d-ed76f889a6c5',
    label: 'Suprimentos, formulários',
  },
  {
    value: '65e406a8-21b8-4bf0-a6f5-d6c04fa7d2f0',
    label: 'Serviço de Brigadistas',
  },
  {
    value: 'b95d18f8-552b-4af4-a315-6886a21006cf',
    label: 'Oxigênio Hospitalar',
  },
  {
    value: '2a3df52e-c497-4242-a0bd-7091f4727a7e',
    label: 'Professor de Música',
  },
  {
    value: 'bc365c9f-b1b1-44a7-b0bb-a9d25e7a70f4',
    label: 'Manutenção de Equipamentos Hospitalares',
  },
  {
    value: '9eb8f8be-9a8d-42f0-bd93-b53932783e3b',
    label: 'Carimbos, chaves',
  },
  {
    value: 'efa07ce6-5761-49aa-aff6-26891327bcb4',
    label: 'Manutenção de equipamentos de informática',
  },
  {
    value: 'dd1b3c84-becd-4c48-84df-798bb46ee043',
    label: 'Kit de Enxoval para Bebês',
  },
  {
    value: '9c2d3e36-1cc9-11ee-be56-0242ac120002',
    label: 'Transporte e destinação de resíduos sólidos',
  },
  {
    value: 'e3a15504-1714-41b9-a861-e56ee1198c95',
    label:
      'Equipamentos e instrumentos, hospitalares, laboratoriais e científicos',
  },
  {
    value: '016a9d7f-7dcf-4d90-bc7e-a63ae13e7487',
    label: 'Protetor Solar',
  },
  {
    value: '236b0b61-d494-493c-982f-c885967c0e9f',
    label: 'Dedetização',
  },
  {
    value: '44ada0e2-d483-4835-a014-c6f1b96eca23',
    label: 'Periféricos / Hardwares',
  },
  {
    value: '46481a70-fecd-49fb-ae46-7141a518d76a',
    label: 'Supervisão de obras',
  },
  {
    value: '44ec4bb4-a0ec-4045-9b94-090837960b5c',
    label: 'Materiais Minerais (Areias)',
  },
  {
    value: '37462871-ff2c-4a9e-95fb-ae0c1e225282',
    label: 'Equipamentos Diversos',
  },
  {
    value: '08c49374-2e3a-4ee4-8330-6295c78152a4',
    label: 'Material escolar',
  },
  {
    value: '76a8f7e0-07e6-4611-853d-4b7ab436c035',
    label:
      'Recursos humanos: ascensorista, copa, porteiro, telefonista e outros',
  },
  {
    value: 'f6cd9d07-8013-4cae-bb98-74f7cd20afbd',
    label: 'Materiais de uso Técnico Hospitalar',
  },
  {
    value: '16129645-fc3c-436c-b738-af108a6461e7',
    label: 'Fisioterapia',
  },
  { value: '5ed40418-993a-4758-819c-8bef91ffd389', label: 'Container' },
  {
    value: '7299cb23-2550-430c-bd5b-17ee55293887',
    label: 'Carrinhos em geral, rodízios',
  },
  {
    value: '39aa01af-973a-45cc-b3d9-4e5cb8b5c513',
    label: 'Lixeiras Ecológicas',
  },
  {
    value: '9ff25a3e-f935-4ff6-9501-09d9152f9d2f',
    label:
      'Materiais de construção, saneamento, hidráulicos, elétricos, sinalização',
  },
  {
    value: '0f5b92d2-842d-488c-a1a0-5ec1248b1a71',
    label: 'Tubo de Concreto Armado',
  },
  {
    value: '5a37965c-9059-4d33-a6ce-cc40613df41d',
    label: 'Bandeira Oficial',
  },
  {
    value: '50dfbaa5-f70e-48ce-8ed7-6c55c4fed642',
    label: 'Forros em PVC',
  },
  {
    value: '5546e9d3-0a78-40e6-aaca-02e1cae77bd0',
    label: 'Materiais Hidráulicos',
  },
  {
    value: '8a49ef2e-6081-4bb8-aa72-99b4f44d66a9',
    label:
      'Aquisição de Equipamentos, Materiais Cirúrgicos e Insumos de uso Veterinário',
  },
  {
    value: 'e7472835-4525-4fa4-bbe1-fd5d5b74632f',
    label: 'Energia Limpa Incentivada',
  },
  {
    value: '5311ea68-081d-412e-8231-46a4b5ee3af5',
    label: 'Agenciamento de passagens aéreas, marítimas e terrestres',
  },
  {
    value: '3882bf5f-7582-4605-93af-0a162608a4fa',
    label: 'Máquinas pesadas e equipamentos de construção',
  },
  {
    value: '43a32575-65b4-4eaa-b994-a04e8361ccfa',
    label: 'Válvulas e Conexões',
  },
  {
    value: '90e59a1b-d1c6-4dcb-9089-b6cad74246ad',
    label: 'Construção de Alambrado',
  },
  {
    value: '6faad5fc-c9a2-42d3-b6d7-cec023975b4e',
    label: 'Sistema de Medição de Água Bruta',
  },
  {
    value: '3fcde4d6-a385-42b0-95e2-ebcc520811d5',
    label: 'Serviço de Transporte Escolar',
  },
  {
    value: 'bf7b3857-1280-45ab-8a61-67662b5d8e3b',
    label: 'Consultoria em Comércio Eletrônico',
  },
  {
    value: 'b45dd382-62da-41b0-bb43-d66ce6820154',
    label: 'Irrigação, hidrologia',
  },
  {
    value: '04c83510-f389-4f49-96f2-a057202061ee',
    label: 'Iluminação',
  },
  {
    value: '4a245420-7233-4298-8de8-75aa94a63d34',
    label: 'Refletores para IIuminação',
  },
  {
    value: '8b5d62f0-d98a-4b90-b854-cb29df80eff2',
    label: 'Serviço de Gerenciamento da Manutenção da Frota',
  },
  {
    value: 'ceccfe41-24cd-4a6c-8a7f-33dbba6e27a0',
    label: 'Utensílios de copa e cozinha',
  },
  {
    value: '57a0874a-c49c-4bbd-85d6-0142b1e1f269',
    label: 'Transporte Intermunicipal',
  },
  {
    value: '67cc88e1-24bf-404e-be2a-a1f940d47b10',
    label: 'Locação de Equipamentos e/ou Ferramentas',
  },
  {
    value: 'b1d919d1-f859-4167-bd46-af2600a28d4a',
    label: 'Serviço de Protocolo e Gerenciamento de Processos Digitais',
  },
  {
    value: '71babd2a-f368-4c7b-a286-32748c4e5730',
    label: 'Manutenção e/ou recarga',
  },
  {
    value: '37a4911b-63b1-457f-93d2-0127de695828',
    label: 'Locação de Caminhão',
  },
  {
    value: '9e895734-b5e1-466d-b063-45d76e4c9d27',
    label: 'Dieta enteral e parenteral',
  },
  {
    value: 'de0fa253-a764-4819-8487-110338583128',
    label:
      'Equipamentos para escritórios, máquinas ( fac-símile, mimeógrafo, franqueadora, guilhotina, fragmentadora, contadores, escrever e calcular e outros)',
  },
  {
    value: '73478e92-8c88-4ae8-ab08-0b30009698cc',
    label: 'Coleta de lixo e limpeza urbana',
  },
  {
    value: '2457f996-fa69-4155-b9aa-bfdc45e92e2f',
    label:
      'Telecomunicações, sistemas, cabos, telefones, rádio-chamada, transceptores',
  },
  {
    value: '3b0c8542-7b45-4540-b4a2-d6c847fc21d3',
    label: 'Palestrante',
  },
  {
    value: '719a18c4-1d05-11ee-be56-0242ac120002',
    label: 'Cabos de aço',
  },
  {
    value: '424952bc-c15d-4c85-b859-d9568bdc2c99',
    label: 'Paisagismo, jardinagem, plantio, cortes e podas',
  },
  {
    value: '176dc085-fa5a-4612-a54e-d93f0d00022c',
    label: 'Aquisição/serviços de caminhão pipa',
  },
  { value: '7a5fed19-0634-45dd-b4b8-10a863bb8b37', label: 'Gases' },
  {
    value: 'c8680466-3b0d-465c-b298-bbac8801410a',
    label: 'Construção de UBS',
  },
  {
    value: '9b33ee50-8b7a-47c2-bab8-10da5d1768f7',
    label:
      'Serviços: engenharia clínica, exame médico ocupacional, admissional, serviços médicos e análises laboratoriais',
  },
  {
    value: 'baa636e6-1b45-4d2e-afe6-a825c93b5572',
    label: 'Tanques, reservatórios, caixa d"água',
  },
  {
    value: '2531afea-acf0-4de8-bb3f-6e4f82a68419',
    label: 'Brindes, medalhas, troféus, brasões, distintivos',
  },
  {
    value: '4ac28b29-83ee-499d-9ef5-704ff1c0be79',
    label: 'Limpeza de galerias, dutos, dragagem, desassoreamento',
  },
  { value: 'f2c24477-7cc8-4ac5-83a0-145ed7677b80', label: 'Barraca' },
  {
    value: '4b363628-ac02-4a74-a1fc-2580a9cffaf1',
    label: 'Construção/Reformas de Canteiros',
  },
  {
    value: '451d05d1-2378-4846-8ee4-c37668f40885',
    label: 'Cabines Sanitárias, banheiros químicos',
  },
  { value: '1e707720-9eb1-498f-8601-b9df21250ad2', label: 'Tablet' },
  {
    value: '8a79d3e6-9d7d-4aa6-bbd9-1c7d400b4236',
    label: 'Rações para animais',
  },
  {
    value: '8200ce7b-b190-4c79-bafc-9c2967aa0671',
    label: 'Painéis, luminosos, faixas, displays, plaqueta patrimonial',
  },
  {
    value: '21da8101-83f7-4590-9cfd-cba06f6ded8e',
    label: 'Serviços relacionados a Calhas, Rufos, Cumeeiras e Telhas',
  },
  {
    value: '7155b916-44ba-4a26-85d7-e7a139afadf2',
    label: 'Veterinária e vacinas',
  },
  {
    value: '8a9059df-04ed-4507-9e70-3898b077cfbd',
    label: 'Mudas de Plantas',
  },
  {
    value: 'a7dcc03f-d04e-4ec1-a32c-21745696e957',
    label: 'Locação de Banheiro Químico',
  },
  {
    value: '44739ada-d1ed-4826-92ad-4d7364cbc96b',
    label: 'Prestação de Serviço - Taxas em Geral',
  },
  {
    value: '7b8e61ce-fedc-4279-bd72-8894de593d8a',
    label: 'Prestação de Serviço Técnico Previdenciário na Área Atuarial',
  },
  {
    value: 'f0eedc4d-db1a-4aea-9db0-2e8b2518ad83',
    label: 'Locação e aquisição de Imóveis',
  },
  {
    value: 'cde8f3d3-6b44-48bc-8e4f-2f9ce2cf0959',
    label: 'Silos, depósitos, armazéns',
  },
  {
    value: '6235cee3-58be-417f-b171-cb3196877ec7',
    label: 'Cobertura e material fotográfico, filmagem',
  },
  {
    value: 'e7292ca8-3eb1-4079-a75f-9b6ffec9c833',
    label:
      'Transportes - veículos, aeronaves, embarcações, carros, utilitários, caminhões, motos, barcos, navios, helicópteros, aviões e suas respectivas peças',
  },
  {
    value: '38ca3eb9-59cd-4729-a7aa-a7371e846dc2',
    label: 'Cine, som, ótica, TV e projetores',
  },
  {
    value: '66d5c1aa-6282-4ce3-b543-e9775a00b6b3',
    label: 'Incineração, tratamento de resíduos sólidos',
  },
  {
    value: '9a6bbe08-061a-4216-9f22-f24db3569a6e',
    label: 'Sinalização semafórica, controle do trânsito, radares',
  },
  {
    value: 'e99d01b9-4a4f-44fc-925c-6c64d1ba0053',
    label: 'Mudas, sementes e outros produtos agrícolas',
  },
  {
    value: '9429fbd2-6a17-4d8a-b242-c3ea0492a38c',
    label: 'Contratação de Empresa Especializada em Aterro Sanitário',
  },
  {
    value: 'cd179a5b-d906-4ae1-9da6-f60c7951ecb6',
    label: 'Estágio, estagiários',
  },
  {
    value: '204ea9ba-260b-4e2f-94ae-48f8d6f0619e',
    label: 'Refeição, combustível, transporte',
  },
  {
    value: '2553a909-4118-43b8-b7ab-99cb43216cb0',
    label: 'Lavagem de roupa',
  },
  { value: '616aa23c-7515-4d2a-938c-abca61ea0184', label: 'Calçados' },
  {
    value: '59c23e43-2d8b-46e8-9395-b61ce4b9b34a',
    label: 'Fralda Geriátrica',
  },
  {
    value: 'adf34b2f-2168-4044-ae32-0a55934b769f',
    label:
      'Serviços de Consultoria Para Elaboração do Plano de Mobilidade Urbana',
  },
  {
    value: '465af16d-07f5-4b6d-90bf-dfa252e0506f',
    label: 'Lanchonete, restaurante, bar e locação de máquinas de bebidas',
  },
  {
    value: 'a73d0def-3814-4f4f-af76-a98322516dca',
    label: 'Bancária/instituição financeira e lotérica',
  },
  {
    value: 'd7cfb5ea-cb4f-4ba7-838a-65d8029ed640',
    label:
      'Locação de máquinas médias, máquinas pesadas e caminhões com combustível, operadores e/ou motoristas',
  },
  {
    value: 'dec2ade5-5191-473b-99d9-16961e1732cd',
    label: 'Contratação de Empresa para Infraestrutura - Contenção',
  },
  {
    value: '601f649e-d3b9-465c-b5da-53faa683b4d0',
    label: 'Equipamentos e material contra-incêndio e serviços',
  },
  {
    value: 'e0350cd3-fd84-4e94-b505-179ef9f96947',
    label: 'Inservíveis e Sucatas',
  },
  {
    value: 'e17e9a90-489e-4940-b18b-44c6c7834ccd',
    label: 'Locação de Veículo',
  },
  {
    value: '290b1fad-6087-4a63-a458-6a9ca57110d8',
    label:
      'Empresa Especializada em Cirurgia de Castração com Microchipagem',
  },
  {
    value: 'c3f32651-2fe0-4b01-8804-f399a049d134',
    label: 'Desentupidora',
  },
  {
    value: 'a48e2900-d771-459c-beed-ea3bde25555f',
    label: 'Contratação de Leito Hospitalar Psiquiátrico',
  },
  {
    value: '2e9c9c78-c170-4c9f-8d65-f2b227e847db',
    label: 'Quarto, sala, cozinha, clubes/lazer',
  },
  {
    value: '69b082cb-ed34-43bf-8d00-7eabcc83ef29',
    label: 'Carrinho de Limpeza',
  },
  {
    value: 'a62d325a-4030-42a6-847c-74b1e70a3592',
    label: 'Recomposição Asfáltica',
  },
  {
    value: 'c0589d0e-cb97-47e0-90a9-4692554b02fc',
    label: 'Borracharia',
  },
  {
    value: 'd37eccea-099e-4a61-acbb-3f6224a504cd',
    label: 'Plano de Segurança de Barragem',
  },
  {
    value: '3f6e755f-4f3a-452a-a9c4-767136ad1023',
    label: 'Estudo situacional, planos de ação e intervenções',
  },
  {
    value: '633b8baf-23cf-4de6-8f4d-b1dc2464da0a',
    label: 'Malas, malotes e encerados',
  },
  {
    value: 'f9bd3837-4dba-409f-b372-074d55699b2e',
    label: 'Empresa Especializada em Seleção/Contratação (Cargos, Eleições)',
  },
  {
    value: '5a5c212d-4353-419a-b7a2-5644eeaac323',
    label:
      'Análise Laboratorial em Amostra de Efluente de Estação de Tratamento de Esgoto',
  },
  {
    value: '2061206f-b56f-4f66-b19b-d6705671bc6a',
    label: 'Concessões especiais e outros',
  },
  {
    value: '0bec8b95-95c8-4e99-b48c-0da4982d880f',
    label: 'Exames de Ultrassonografia',
  },
  {
    value: 'af48fda0-f17e-4f3f-85e7-a915e580ab87',
    label: 'Rastreamento de Veículos',
  },
  { value: 'eded882a-5d65-4c09-8842-db02d8442811', label: 'Balanças' },
  {
    value: '6cee1c9e-0db4-4350-b111-cafb2be87ca6',
    label: 'Tapeçaria, persianas, cortinas e decoração',
  },
  {
    value: 'cf07fda8-8698-41ec-b1bb-b1bf6019033d',
    label: 'Insumos para uso Veterinário',
  },
  {
    value: 'd3b739f4-b7f2-466d-8a04-f5cb6ee54946',
    label: 'Gradil Rígido Modular',
  },
  {
    value: '44c91c55-beb1-430d-b14e-e5c1f948c8fb',
    label: 'Contratação de Instituição de Ensino Superior',
  },
  {
    value: 'c9ce34b1-32f0-468b-902d-34fe86c2651e',
    label: 'Cercamento e alambrado',
  },
  {
    value: '96811357-a572-4540-89a6-bb6a9291326f',
    label: 'Cartão de Benefício',
  },
  {
    value: '4b66ac3b-1a89-47a3-a163-32b546f74092',
    label:
      'Elevadores, monta carga, escadas rolantes, esteiras transportadoras',
  },
  {
    value: 'b1c67ae8-5190-4633-8b41-65ded52bc721',
    label: 'Locação de Container',
  },
  {
    value: 'cbc49ef4-b110-4e77-8626-9a366570f763',
    label: 'Produtos Natalinos, Artesanato e Fogos de Artifício',
  },
  {
    value: '5a6bcaca-347a-41c5-a5fa-b1129c3d0591',
    label: 'Concurso Público',
  },
  {
    value: 'df126d18-e28f-4df4-bcc1-c74e830d83e6',
    label:
      'Microfilmagem, arquivamento, gerenciamento eletrônico de documentos (GED), digitalização, biblioteconomia',
  },
  {
    value: '54a3ce73-9c3a-4093-a9c2-0b3d5468778e',
    label: 'Empilhadeiras, pallets',
  },
  {
    value: 'c7a84f37-451b-4c3e-8086-27315593f0a6',
    label:
      'Satélite, sinais, sistema de posicionamento global, rastreamento, radar aéreo controlador de vôo, sistema de navegação aérea, controle de espaço aéreo',
  },
  {
    value: '04a3adce-a9e7-4e83-9640-583e1de21d3a',
    label:
      'Câmaras frigoríficas, cozinha industrial, lavanderia, limpeza e outros',
  },
  {
    value: 'a639b987-e97b-4a92-81a9-ca02e800ae9e',
    label: 'Magnético, pvc, crachá e outros',
  },
  {
    value: '73a92102-66f5-43db-bbf0-52d5bbf166cb',
    label: 'Serviços de moto-boy e transporte: passageiros, documentos',
  },
  {
    value: '3ba74d4a-d1b8-4a9d-9408-1790b398da21',
    label: 'Tratamento de água, análises de água',
  },
  { value: '6608867b-92fe-424b-ad0b-038d02949452', label: '---' },
  {
    value: '7f89062f-fc8a-4c98-9b8d-6e183f2f1c4d',
    label: 'Medição em geral',
  },
  {
    value: 'd48e5b1c-7bec-4cf8-b585-1d09c05b79bd',
    label:
      'Segurança: alarme, portas especiais, cofres, circuito de TV (CFTV), catracas, detector de metais, raio-x de bagagem',
  },
  {
    value: '4b850633-5344-4313-aba7-4c5436cde025',
    label: 'Armas, munições, bélicos e explosivos',
  },
  {
    value: '07d7a9d1-bb90-4b17-8e58-914fafcff62b',
    label: 'Propaganda, assessoria de imprensa',
  },
];
