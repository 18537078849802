import { PaginatorContainer } from '@components/Paginator/styles';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useTheme } from 'styled-components';
import { PaginationType } from '../../pages/Home';

type PaginatorProps = {
  totalPages: number;
  currentPage: number;
  take: number;
  onPageChange: (page: PaginationType) => void;
};

export const Paginator = ({
  onPageChange,
  totalPages,
  currentPage,
  take = 9,
}: PaginatorProps) => {
  const theme = useTheme();

  let pages: number[];

  if (totalPages >= 4) {
    const paginationLimit = 4;
    pages = Array.from({ length: paginationLimit }, (_, i) => currentPage + i);
  } else {
    pages = Array.from({ length: totalPages }, (_, i) => currentPage + i);
  }

  const handlePage = (page: number) => {
    if (page === currentPage) return;

    onPageChange({ skip: page, take });
  };

  const handlePreviousPage = () => {
    if (currentPage === 0) return;

    onPageChange({ skip: currentPage - 1, take });
  };

  const handleNextPage = () => {
    if (currentPage === totalPages) return;

    onPageChange({ skip: currentPage + 1, take });
  };

  if (totalPages <= 1) return null;

  return (
    <PaginatorContainer>
      <button onClick={handlePreviousPage} className="navigation">
        <CaretLeft size={18} color={theme.light} />
      </button>

      {pages.map((page) => (
        <button
          key={page}
          className={currentPage === page ? 'active' : ''}
          onClick={() => handlePage(page)}
        >
          {page + 1}
        </button>
      ))}

      <button onClick={handleNextPage} className="navigation">
        <CaretRight size={18} color={theme.light} />
      </button>
    </PaginatorContainer>
  );
};
