export const FolderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        stroke="#004AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.217 13.783h12.174a.61.61 0 00.609-.61V6.479c0-.67-.548-1.217-1.217-1.217h-9.13c-.67 0-1.218.548-1.218 1.217v5.479"
      />
      <path
        stroke="#004AA0"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.435 6.478v6.087c0 .67-.548 1.218-1.218 1.218-.67 0-1.217-.548-1.217-1.218V1.61A.61.61 0 011.609 1h2.544c.256 0 .48.158.572.396l.39 1.034a.613.613 0 00.572.396h6.884c.67 0 1.218.548 1.218 1.217v1.218h-9.13c-.67 0-1.218.542-1.218 1.217h-.006z"
      />
    </svg>
  );
};
