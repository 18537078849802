import styled from 'styled-components';

export const CardEditalContainer = styled.div`
  background: ${({ theme }) => theme.light};
  padding: 22px;
  border-radius: 12px;
  display: grid;
  gap: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    div.titles {
      display: grid;
      gap: 4px;

      span {
        font-weight: bold;
        color: ${({ theme }) => theme.secondary};
        font-size: 1.4rem;
      }

      h6 {
        font-weight: bold;
        font-size: 1.6rem;
        color: ${({ theme }) => theme.primary};
      }
    }

    div.menu-icon {
      display: flex;
      place-items: center;
      padding: 6px;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.primary};

      svg {
        height: 26px;
        width: 24px;
      }
    }
  }

  section.infos {
    width: 100%;
    display: grid;
    gap: 10px;
    flex: 1;
    div.row-info {
      display: flex;
      align-items: center;
      gap: 12px;

      &.footer {
        justify-content: space-between;
        flex-wrap: wrap;
      }

      div.icon {
        svg {
          height: 20px;
          width: 22px;
        }
      }

      div.info {
        display: grid;
        gap: 4px;

        label {
          color: ${({ theme }) => theme.secondary};
          font-size: 1.5rem;
          font-weight: bold;
        }

        span {
          color: ${({ theme }) => theme.secondary};
          font-size: 1.3rem;
        }
      }
    }
  }

  footer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    display: flex;

    button {
      width: 100%;
      display: grid;
      place-items: center;
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.light};
      font-size: 1.4rem;
      font-weight: bold;
      padding: 12px 0;
      border-radius: 5px;
      transition: filter 0.2s ease-in-out;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`;
